import Axios from "../../api/Axios";

const fetchDispositions = () => {
  return async (dispatch) => {
    try {
      let dispositions = await Axios.get("/webApp/getDispositions");
      dispatch({ type: "UTILS_FETCH_DISPOSITIONS", payload: dispositions.data });
    } catch (e) {
      console.log(e);
    }
  };
};

const fetchLocals = () => {
  return async (dispatch) => {
    try {
      let locals = await Axios.get("/webapp/locals");
      dispatch({ type: "UTILS_FETCH_LOCALS", payload: locals.data });
    } catch (e) {
      console.log(e);
    }
  };
};

const fetchTransporters = () => {
  return async (dispatch) => {
    try {
      let locals = await Axios.get("/webapp/transporters");
      dispatch({ type: "UTILS_FETCH_LOCALS", payload: locals.data });
    } catch (e) {
      console.log(e);
    }
  };
};

const fetchDataTransporter = (transporterID) => {
  
  return async (dispatch) => {
    try {
      let transporter = await Axios.get("/webapp/getTransporter", { params: { transporterID } });
      dispatch({ type: "UTILS_FETCH_DATA_TRANSPORTER", payload: transporter.data });
    } catch (e) {
      console.log(e);
    }
  };
};
const fetchData = () => {
  return async (dispatch) => {
    try {
      const response = await Axios.get("/adminTool/removals/getDataCreateRemoval");
      const customers = await Axios.get("/adminTool/customers");
      const dispositions = await Axios.get("/adminTool/dispositions");
      console.log(response);
      dispatch({
        type: "UTILS_FETCH_LOCALS",
        payload: response.data.locals
      });
      dispatch({
        type: "UTILS_FETCH_TRANSPORTERS",
        payload: response.data.transporters,
      });
      dispatch({
        type: "UTILS_FETCH_VEHICLESID",
        payload: response.data.transporters,
      });
      dispatch({
        type: "UTILS_FETCH_CUSTOMERS",
        payload: customers.data,
      });
      dispatch({
        type: "UTILS_FETCH_DISPOSITIONS",
        payload: dispositions.data,
      });
      
    } catch (e) {
      console.log(e);
    }
  };
};
export default {
  fetchLocals,
  fetchDispositions,
  fetchTransporters,
  fetchDataTransporter,
  fetchData
};
