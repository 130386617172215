import React from "react";
import logo from "../../../resources/images/logo-color.png";
import logoWhite from "../../../resources/images//logo.png";
import { useAppContext } from "../../../context/AppContext";
import { Grid, Typography } from "@material-ui/core";
export default function Footer() {
  const { themeColor } = useAppContext();
  return (
    <Grid container>
      <Grid item align="center" xs={12} style={{ padding: 20 }}>
        <img
          src={themeColor === "light" ? logo : logoWhite}
          style={{ height: 120, margin: 20 }}
          alt="Logo acción circular"
        />
        <Typography variant="h5" component="p" align="center">
          Ponemos la tecnología al servicio de la economía circular
        </Typography>
      </Grid>
    </Grid>
  );
}
