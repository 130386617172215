import React from "react";

import {
  Card,
  CardContent,
  Grid,
  Button,
  ButtonGroup,
  Paper,
  Typography,
} from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import MapIcon from "@material-ui/icons/Map";
import SyncIcon from "@material-ui/icons/Sync";
import SettingsIcon from "@material-ui/icons/Settings";
import { makeStyles } from "@material-ui/core/styles";
import swal from 'sweetalert';

import Header from "../../Navigation/Header";
import { SensorsContextProvider } from "../../../context/SensorsContext";
import View from "./View";
import Locations from "./Locations";
import Configure from "./Configure";
import ChartsFilled from "./ChartsFilled";
import Table from "./Table";
// import { useAppContext } from "../../../context/AppContext";

const styles = makeStyles(() => ({
  buttonGroup: {
    ['@media (max-width:320px)']: {
      fontSize: '0.5rem',
      display: 'flow-root',
      textTransform: 'none'
    }, ['@media (max-width:425px)']: {
      fontSize: '0.5rem',
      display: 'flow-root',
    },
  },
}))

const titles = {
  TABLE_SENSORS: "Tus sensores",
  FILL_SENSORS: "Gráfico de llenado de sensores",
  LOCATION: "Distribución geográfica de sensores",
  CONFIGURE: "Configurar sensores",
};
function Index() {
  // const { userType } = useAppContext();
  const [view, setView] = React.useState("TABLE_SENSORS");
  const [sensor, setSensor] = React.useState({});
  const classes = styles();

  return (
    <Card>
      <CardContent>
        <Header
          title={
            <Typography variant="h4">
              <SyncIcon style={{ fontSize: 34, marginBottom: 6 }} />{" "}
              {titles[view]}
            </Typography>
          }
        />
        <Grid container spacing={2}>
          {/* {userType === "LOCAL" ? (
            <Grid item xs={12}>
              <hr />
            </Grid>
          ) : ( */}
              <Grid item xs={12} align="end">
                <hr />
                <ButtonGroup color="primary" size="small">
                  <Button
                    variant={view === "TABLE_SENSORS" ? "contained" : "outlined"}
                    startIcon={<SyncIcon />}
                    onClick={() => setView("TABLE_SENSORS")}
                    className={classes.buttonGroup}
                  >
                    Sensores
                </Button>
                  <Button
                    variant={view === "FILL_SENSORS" ? "contained" : "outlined"}
                    startIcon={<BarChartIcon />}
                    onClick={() => setView("FILL_SENSORS")}
                    className={classes.buttonGroup}
                  >
                    Llenado
                </Button>
                  <Button
                    variant={view === "LOCATION" ? "contained" : "outlined"}
                    startIcon={<MapIcon />}
                    onClick={() => setView("LOCATION")}
                    className={classes.buttonGroup}
                  >
                    Distribución
                </Button>
                  <Button
                    variant={view === "CONFIGURE" ? "contained" : "outlined"}
                    startIcon={<SettingsIcon />}
                    onClick={() => { setView("CONFIGURE"); swal("Si desea hacer cambios en la configuración del sensor debe solicitarlos a la Administración de Acción Circular") }}
                    className={classes.buttonGroup}
                  >
                    Configuración
                </Button>
                </ButtonGroup>
                <hr />
              </Grid>
            {/* )} */}
          <Paper style={{ width: "100%", padding: 20 }}>
            {view === "VIEW_SENSOR" && <View setView={setView} sensor={sensor} />}
            {view === "LOCATION" && <Locations />}
            {view === "CONFIGURE" && <Configure />}
            {view === "FILL_SENSORS" && <ChartsFilled />}
            {view === "TABLE_SENSORS" && <Table setSensor={setSensor} setView={setView} />}
          </Paper>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default function Sensors() {
  return (
    <SensorsContextProvider>
      <Index />
    </SensorsContextProvider>
  );
}
