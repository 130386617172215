import React from "react";
import {
  AreaChart,
  Area,
  ResponsiveContainer,
  XAxis,
  Label,
  YAxis,
  Tooltip,
  ReferenceLine,
} from "recharts";
import PropTypes from "prop-types";

export default function Charts({ data, helpers, removals }) {
  const isMobile = false;

  const [keys, setKeys] = React.useState([]);
  const [dataG, setDataG] = React.useState([]);

  React.useEffect(() => {
    if (data.length) {
      setKeys(Object.keys(data[0]));

      let temp = [];
      data.forEach((el) => {
        let aux = {};
        Object.keys(el).map((mat) => {
          if (mat === "name") {
            aux["Fecha"] = el[mat];
          } else {
            aux[helpers[mat].name] = el[mat];
          }
        });
        
        temp.push(aux);
      });
temp = temp.sort(function(a,b){
  // Turn your strings into dates, and then subtract them
  // to get a value that is either negative, positive, or zero.
  var datePartsA = a.Fecha.split("/");
  var datePartsB = b.Fecha.split("/");

// month is 0-based, that's why we need dataParts[1] - 1
var dateObjectA = new Date(+datePartsA[2], datePartsA[1] - 1, +datePartsA[0]); 
var dateObjectB = new Date(+datePartsB[2], datePartsB[1] - 1, +datePartsB[0]); 
  return dateObjectA - dateObjectB;
});
console.log(temp);
      setDataG(temp);
    }
  }, [data, helpers]);

  return (
    <ResponsiveContainer width="100%" aspect={isMobile ? 4.0 / 4.0 : 4.0 / 1}>
      <AreaChart
        width={500}
        height={400}
        data={dataG}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis dataKey="Fecha"> <Label value="Fecha" offset={-5}  position="insideBottom" /></XAxis>
        <YAxis ><Label value="Llenado" offset={15} angle="-90" position="insideLeft" /></YAxis>
        <Tooltip formatter={(Llenado) => Llenado + " %"} />
        {removals.map((el, i) => (
          <ReferenceLine
            key={i + "removal_line"}
            x={el.Fecha}
            stroke="#E74C3C"
            label="Retiro"
          />
        ))}

        {keys.map((key, i) => {
          if (key !== "name") {
            return (
              <defs key={i}>
                <linearGradient id={key} x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={helpers[key].color}
                    stopOpacity={0.3}
                  />
                  <stop
                    offset="95%"
                    stopColor={helpers[key].color}
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
            );
          } else {
            return <></>;
          }
        })}
        {keys.map((key, i) => {
          if (key !== "name") {
            return (
              <Area
                key={i + "graf"}
                type="monotone"
                dataKey={helpers[key].name}
                stroke={helpers[key].color}
                strokeWidth={1}
                fillOpacity={1}
                fill={"url(#" + key + ")"}
              />
            );
          } else {
            return <></>;
          }
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
}

Charts.propTypes = {
  data: PropTypes.array.isRequired,
  helpers: PropTypes.object.isRequired,
  removals: PropTypes.array.isRequired,
};
