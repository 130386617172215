import React, { useEffect, useState } from "react";
import { TextField, Grid, MenuItem, Typography, Chip, formatMs } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from 'react-select';
import { useSelector } from "react-redux";
import materialsList from "../../../../resources/materials";
const style = {
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};
const list_materials = materialsList.materials;

export default function Disp({ formData, setFormData, removal, disabled }) {
  const utils = useSelector((state) => state.utils);

  const handleDisp = (matr, event) => {

    let temp = {
      ...formData.dispositions,
      [matr]: event.value
    };
    setFormData({
      ...formData,
      dispositions: temp,
    });
  };

  const onChangeDispNote = (matr, event) => {
    let temp = {
      ...formData.text_list,
      [matr]: event.target.value
    };
    setFormData({
      ...formData,
      text_list: temp,
    });

  };

  const [autocompleteValues, setAutocompleteValues] = useState({
    local: "",
    transporter: "",
    disposition: "",
  });

  useEffect(() => {

    if (formData.localID || formData.transporterID || formData.dispositionID) {
      setAutocompleteValues({
        ...autocompleteValues,
        local: utils.locals.find((local) => local._id === formData.localID),
        disposition: utils.dispositions.dispositions.find((disposition) => disposition._id === formData.dispositionID),
      });
    }
  }, [formData.localID, formData.transporterID, formData.dispositionID]);



  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Typography variant="h6" align="center">Paso 4: Plantas de disposición</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>

        {

          Object.keys(list_materials).map((material) => (

            formData.materials[material.toUpperCase()] > 0 ? (
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center"
              >

                <Grid item xs={12} sm={2}>

                  <Typography variant="body2" align="center" color="textSecondary">{list_materials[material].name}: </Typography>

                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete

                    options={utils.dispositions.dispositions.map(opt => ({ label: opt.name, value: opt._id }))}
                    getOptionLabel={(option) => option.label}
                    disabled={disabled}
                    onChange={(e, newValue) => handleDisp(material.toUpperCase(), newValue)}
                    value={
                      formData.dispositions[material.toUpperCase()] != null
                        ? {
                          label: utils.dispositions.dispositions.find(
                            (element) => element._id === formData.dispositions[material.toUpperCase()]
                          ).name,
                          value: formData.dispositions[material.toUpperCase()],
                        }
                        : null
                    }
                    renderInput={(params) => <TextField {...params} {...style} label="Selecciona una opción" />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    disabled={disabled}
                    label={"Nota"}
                    value={formData.text_list[material.toUpperCase()]}
                    onChange={(e) => onChangeDispNote(material.toUpperCase(), e)}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />


                </Grid>
              </Grid>
            ) : ""

          ))}
      </Grid>
    </Grid>
  );
}
