import React from "react";
import Axios from "../api/Axios";
import { useAppContext } from "./AppContext";
const SensorsContext = React.createContext();

export function SensorsContextProvider(props) {
  const [locals, setLocals] = React.useState([]);
  const { helpers } = useAppContext();
  React.useEffect(() => {
    getLocals().then((response) => {
      setLocals(response.data);
    });
  }, []);
  const value = React.useMemo(() => {
    return {
      locals,
      getSensorsTable,
      getSensorsFilled,
      getSensorsLocation,
      getSensorsConfigureByLocal,
      helpers,
    };
  }, [locals]);
  return <SensorsContext.Provider value={value} {...props} />;
}

export function useSensorsContext() {
  const context = React.useContext(SensorsContext);

  if (!context) {
    throw new Error("No suscrito");
  }
  return context;
}

const getLocals = async () => {
  return await Axios.get("/webApp/locals");
};

const getSensorsTable = () => {
  return new Promise((resolve, reject) => {
    Axios.get("/webApp/sensors").then((response) => {
      resolve(response.data);
    });
  });
};

const getSensorsFilled = () => {
  return new Promise((resolve, reject) => {
    Axios.get("/webApp/sensors/filled").then((response) => {
      resolve(response.data);
    });
  });
};

const getSensorsLocation = () => {
  return new Promise((resolve, reject) => {
    Axios.get("/webApp/sensors/location").then((response) => {
      resolve(response.data);
    });
  });
};

const getSensorsConfigureByLocal = ({ localID }) => {
  return new Promise((resolve, reject) => {
    Axios.get("/webApp/sensors/sensorsTableByLocal", {
      params: { localID: localID },
    }).then((response) => {
      resolve(response.data);
    });
  });
};