import React from "react";
import { Grid } from "@material-ui/core";

import Map from "./Map";
import { useSensorsContext } from "../../../../context/SensorsContext";

export default function Locations() {
  const { getSensorsLocation } = useSensorsContext();
  const [locations, setLocations] = React.useState([]);

  React.useEffect(() => {
    getSensorsLocation().then((response) => {
      if (response) {
        setLocations(response);
      }
    });
  }, [getSensorsLocation]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Map data={locations} />
      </Grid>
    </Grid>
  );
}
