import React, { useEffect, useState, Fragment } from "react";
import {
  Paper,
  Card,
  Grid,
  CardContent,
  Button,
  TextField,
  MenuItem,
  Typography,
  ButtonGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LocalShipping, AddCircleOutlineOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import RemovalsCurrent from "./Current";
import RemovalsPrev from "./Previus";
import RemovalsList from "./Historic";
import CreateModal from "./CreateModal";
import { useAppContext } from "../../../context/AppContext";
import Header from "../../Navigation/Header";
import ViewModal from "./ViewModal";
import ViewModalTransporter from "./ViewModalTransporter";
import Nothing from "../Dashboard/Nothing";
import actionsRemovals from "../../../redux/actions/removals";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Index() {
  const { userType, entityID, transporterID } = useAppContext();

  const classes = useStyles();
  const [newRemoval, setNewRemoval] = useState(false);
  const [removal, setRemoval] = useState({});
  const [viewRemoval, setViewRemoval] = useState(false);
  const [error, setError] = useState(false);
  const [view, setView] = useState("CURRENT");

  const dispatch = useDispatch();
  const utils = useSelector((state) => state.utils);
  const removals = useSelector((state) => state.removals);
  const dispositions = useSelector((state) => state.dispositions);
  

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 1500);
    }
  }, [error]);

  const { user } = useAppContext();
  /* const transporterID = user._id; */
  let time = "ALL";

  useEffect(() => {
    const fetch = async () => {
      if(userType === "TRANSPORTER"){
        if (view === "CURRENT")
          dispatch(actionsRemovals.fetchTransporter({ transporterID, time }));
        if (view === "PREVIUS")
          dispatch(actionsRemovals.fetchPrevius({ transporterID }));
        if (view === "HISTORIC")
          dispatch(actionsRemovals.fetchHistoricTransporter({ transporterID}));
      
      }else if (removals.localID !== "EMPTY") {
        if (view === "CURRENT")
          dispatch(actionsRemovals.fetchCurrent({ localID: removals.localID }));
        if (view === "PREVIUS")
          dispatch(actionsRemovals.fetchPrevius({ localID: removals.localID }));
        if (view === "HISTORIC")
          dispatch(actionsRemovals.fetchHistoric({ localID: removals.localID }));
      } else {
        if (view === "CURRENT")
          dispatch(actionsRemovals.fetchCurrent({ localID: entityID }));
        if (view === "PREVIUS")
          dispatch(actionsRemovals.fetchPrevius({ localID: entityID }));
        if (view === "HISTORIC")
          dispatch(actionsRemovals.fetchHistoric({ localID: entityID }));
      }
    };
    fetch();
  }, [view, removals.localID, removals]);

  return (
    <Paper className={classes.root}>
      {newRemoval && <CreateModal setNewRemoval={setNewRemoval} localID={entityID} />}
      {viewRemoval && (
        <ViewModal removal={removal} setViewRemoval={setViewRemoval} />
      )}
      <Card>
        <CardContent>
          <Header
            title={
              <Typography component="h2" variant="h4">
                <LocalShipping
                  style={{
                    fontSize: 34,
                    marginBottom: 6,
                  }}
                />{" "}
                {view === "CURRENT" && "Retiros del mes actual"}
                {view === "PREVIUS" && "Retiros del mes anterior"}
                {view === "HISTORIC" && "Retiros históricos"}
              </Typography>
            }
          />
          <hr />
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={4} md={3}>
              {userType === "CUSTOMER" && (
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Sucursales"
                  InputLabelProps={{ shrink: true }}
                  select
                  value={removals.localID}
                  defaultValue={"ALL"}
                  onChange={(e) =>
                    dispatch(
                      actionsRemovals.setLocalID({ localID: e.target.value })
                    )
                  }
                  fullWidth
                  error={error}
                  helperText={error && "Selecciona una sede o sucursal"}
                >
                  <MenuItem value="EMPTY" selected>
                    <em>Ninguno</em>
                  </MenuItem>
                  {utils.locals.map((local, i) => (
                    <MenuItem key={i} value={local._id}>
                      {local.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <ButtonGroup
                fullWidth
                size="small"
                color="primary"
                onClick={
                  removals.localID === "ALL" ? () => setError(true) : null
                }
              >
                <Button
                  variant={view === "CURRENT" ? "contained" : "outlined"}
                  onClick={() => setView("CURRENT")}
                >
                  actual
                </Button>
                <Button
                  variant={view === "PREVIUS" ? "contained" : "outlined"}
                  onClick={() => setView("PREVIUS")}
                >
                  anterior
                </Button>
                <Button
                  variant={view === "HISTORIC" ? "contained" : "outlined"}
                  onClick={() => setView("HISTORIC")}
                >
                  histórico
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
            {userType === "TRANSPORTER" ? "" :
              <Button
                size="small"
                color="primary"
                style={{
                  background:
                    "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
                  color: "#fff",
                }}
                variant="contained"
                startIcon={<AddCircleOutlineOutlined />}
                onClick={() => setNewRemoval(true)}
                fullWidth
              >
                SOLICITAR RETIRO EXTRA
              </Button>}
            </Grid>
          </Grid>
          <hr></hr>
          {(userType === "CUSTOMER" && removals.localID !== "EMPTY") ||
            userType === "LOCAL" || userType === "TRANSPORTER" ? (
              <Fragment>
                {view === "CURRENT" && (
                  <RemovalsCurrent localID={removals.localID} />
                )}

                {view === "PREVIUS" && <RemovalsPrev />}

                {view === "HISTORIC" && (
                  <RemovalsList
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                  />
                )}
              </Fragment>
            ) : (
              <Nothing text="Selecciona una sede o sucursal" />
            )}
        </CardContent>
      </Card>
    </Paper>
  );
}

export default Index;
