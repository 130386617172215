import React from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  ButtonGroup,
  Typography,
} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import littleContainer from "../../../../../resources/images/contenedores-01.png";
import middleContainer from "../../../../../resources/images/contenedores-02.png";
import bigContainer from "../../../../../resources/images/contenedores-03.png";
import giantContainer from "../../../../../resources/images/contenedores-04.png";
import PropTypes from "prop-types";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

export default function Containers({ formData, setFormData, error }) {
  const buttonClick = (action, container) => {
    if (action === "PLUS") {
      setFormData({
        ...formData,
        containers: {
          ...formData.containers,
          [container]: formData.containers[container] + 1,
        },
      });
    } else {
      if (formData.containers[container] > 0) {
        setFormData({
          ...formData,
          containers: {
            ...formData.containers,
            [container]: formData.containers[container] - 1,
          },
        });
      }
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!error ? (
          <Typography variant="body1">
            Selecciona los contenedores que quieres retirar
          </Typography>
        ) : (
          <Typography variant="body1" style={{ color: "#ffbb33" }}>
            <ErrorOutlineIcon style={{ marginBottom: 4 }} /> Debes seleccionar
            mínimo un contenedor
          </Typography>
        )}
      </Grid>

      <Grid item xs={6} sm={3}>
        <Card>
          <CardContent>
            <Badge
              badgeContent={formData.containers.littleContainer}
              {...{
                children: (
                  <img
                    src={littleContainer}
                    alt="contenedor pequeño"
                    style={{
                      maxWidth: "100%",
                      opacity: formData.containers.littleContainer ? 1 : 0.5,
                    }}
                  />
                ),
                color: "primary",
              }}
            />
            <hr></hr>
            <Typography align="center" variant="p">
              Pequeño (80L aprox)
            </Typography>
            <hr></hr>
            <ButtonGroup fullWidth size="small">
              <Button
                color="primary"
                variant="outlined"
                onClick={() => buttonClick("MINUS", "littleContainer")}
                startIcon={<RemoveIcon />}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => buttonClick("PLUS", "littleContainer")}
                endIcon={<AddIcon />}
              />
            </ButtonGroup>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Card>
          <CardContent>
            <Badge
              badgeContent={formData.containers.middleContainer}
              {...{
                children: (
                  <img
                    src={middleContainer}
                    alt="contenedor mediano"
                    style={{
                      maxWidth: "100%",
                      opacity: formData.containers.middleContainer ? 1 : 0.5,
                    }}
                  />
                ),
                color: "primary",
              }}
            />
            <hr></hr>
            <Typography align="center" variant="p">
              Mediano (120L aprox)
            </Typography>
            <hr></hr>
            <ButtonGroup fullWidth size="small">
              <Button
                color="primary"
                variant="outlined"
                startIcon={<RemoveIcon />}
                onClick={() => buttonClick("MINUS", "middleContainer")}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => buttonClick("PLUS", "middleContainer")}
                endIcon={<AddIcon />}
              />
            </ButtonGroup>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Card>
          <CardContent>
            <Badge
              badgeContent={formData.containers.bigContainer}
              {...{
                children: (
                  <img
                    src={bigContainer}
                    alt="contenedor grande"
                    style={{
                      maxWidth: "100%",
                      opacity: formData.containers.bigContainer ? 1 : 0.5,
                    }}
                  />
                ),
                color: "primary",
              }}
            />
            <hr></hr>
            <Typography align="center" variant="p">
              Grande (500L aprox)
            </Typography>
            <hr></hr>
            <ButtonGroup fullWidth size="small">
              <Button
                color="primary"
                variant="outlined"
                startIcon={<RemoveIcon />}
                onClick={() => buttonClick("MINUS", "bigContainer")}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => buttonClick("PLUS", "bigContainer")}
                startIcon={<AddIcon />}
              />
            </ButtonGroup>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Card>
          <CardContent>
            <Badge
              badgeContent={formData.containers.giantContainer}
              {...{
                children: (
                  <img
                    src={giantContainer}
                    alt="contenedor gigante"
                    style={{
                      maxWidth: "100%",
                      opacity: formData.containers.giantContainer ? 1 : 0.5,
                    }}
                  />
                ),
                color: "primary",
              }}
            />{" "}
            <hr></hr>
            <Typography align="center" variant="p">
              Gigantes (1000L aprox)
            </Typography>
            <hr></hr>{" "}
            <ButtonGroup fullWidth size="small">
              <Button
                color="primary"
                variant="outlined"
                startIcon={<RemoveIcon />}
                onClick={() => buttonClick("MINUS", "giantContainer")}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => buttonClick("PLUS", "giantContainer")}
                startIcon={<AddIcon />}
              />
            </ButtonGroup>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

Containers.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};
