const defaultState = {
  current: [],
  previus: [],
  historic: [],
  selector: "COMPLETE",
  localID: "EMPTY",
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case "REMOVALS_FETCH_CURRENT": {
      return { ...state, current: payload };
    }
    case "REMOVALS_FETCH_PREVIUS": {
      return { ...state, previus: payload };
    }
    case "REMOVALS_FETCH_HISTORIC": {
      return { ...state, historic: payload };
    }
    case "REMOVAL_VEHICLEID_NULL": {
      return state;
    }
    case "REMOVALS_SET_RATING": {
      return state;
    }
    case "REMOVALS_INACTIVE": {
      return {...state, current: payload};
    }
    case "REMOVALS_CREATE_BY_SENSORS": {
      return {...state, current: payload};
    }
    case "REMOVALS_SET_DATA_MODAL": {
      return { ...state, createModal: payload };
    }
    case "REMOVALS_SET_LOCALID": {
      return { ...state, localID: payload.localID };
    }
    case "REMOVALS_SET_DATA_SUCCESS": {
      return { ...state, createModal: payload };
    }

    default:
      return state;
  }
}

export default reducer;
