import React from "react";
import {
  Typography,
  Grid,
} from "@material-ui/core";

export default function Description({ removal }) {
  return (
    <Grid item xs={12}>
      <Typography
        variant="h5"
      >
        Descripción del retiro
      </Typography>
      <Typography
        multiline
        label="Descripción"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        style={{ padding: "2%", fontSize:"19px" }}
      >
        {removal.notes}
      </Typography>
    </Grid>
  );
}
