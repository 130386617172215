import React from "react";
import { Grid, Button, ButtonGroup, Typography } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { endOfDay } from "date-fns";
import MomentUtils from "@date-io/moment";

import { useDashboardContext } from "../../../context/DashboardContext";

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export default function Dates() {
  const {
    setDateInit,
    setDateFinish,
    dateInit,
    dateFinish,
    selectDate,
    setSelectDate,
  } = useDashboardContext();

  React.useEffect(() => {
    const now = new Date();

    if (selectDate === 0) {
      setDateInit(new Date(now.getFullYear(), now.getMonth(), 1));
      setDateFinish(
        new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59)
      );
    }
    if (selectDate === 1) {
      setDateInit(new Date(2018, 1, 1));
      setDateFinish(
        new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59)
      );
    }
  }, [selectDate, setDateInit, setDateFinish]);


  const handleSetDateInit = ({ date }) => {
    setDateInit(new Date(date));
  };
  const handleSetDateFinish = ({ date }) => {
    setDateFinish(new Date(date));
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        {selectDate === 0 ? (
          <Typography variant="h5" component="h4">
            Estadísticas de{" "}
            <strong style={{ color: "#303f9f" }}>
              {months[new Date().getMonth()]}
            </strong>
          </Typography>
        ) : selectDate === 1 ? (
          <Typography variant="h5" component="h4">
            Estadísticas{" "}
            <strong style={{ color: "#303f9f" }}>acumuladas</strong>
          </Typography>
        ) : (
              <Typography variant="h5" component="h4">
                Estádisticas en intervalo
              </Typography>
            )}
      </Grid>
      <Grid item>
        <ButtonGroup size="small" color="primary">
          <Button
            className={selectDate === 2 ? "btn blue-gradient" : ""}
            onClick={() => setSelectDate(2)}
            size="small"
          >
            Elegir intervalo
          </Button>
          <Button
            className={selectDate === 1 ? "btn blue-gradient" : ""}
            color={"primary"}
            onClick={() => setSelectDate(1)}
            size="small"
          >
            Acumulado
          </Button>
          <Button
            className={selectDate === 0 ? "btn blue-gradient" : ""}
            color={"primary"}
            onClick={() => setSelectDate(0)}
            size="small"
          >
            Este mes
          </Button>
        </ButtonGroup>
      </Grid>
      {selectDate === 2 && (
        <Grid item>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Fecha inicial"
                  inputVariant="outlined"
                  value={dateInit}
                  maxDate={dateFinish}
                  onChange={(date) => handleSetDateInit({ date })}
                  margin="dense"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Fecha final"
                  inputVariant="outlined"
                  value={dateFinish}
                  minDate={dateInit}
                  onChange={(date) => handleSetDateFinish({ date: endOfDay(new Date(date)) })}
                  margin="dense"
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <Button
                className="btn blue-gradient"
                style={{ marginRight: 50, height: 40, marginTop: 8 }}
                onClick={() => setSelectDate(2)}
                fullWidth
                color="primary"
              >
                Buscar
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
