import React from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import Jimp from "jimp";
import { DropzoneArea } from "material-ui-dropzone";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export default function General({ formData, setFormData, error, userType }) {
  const utils = useSelector((state) => state.utils);

  const handleSetImages = (files) => {
    Array.from(files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .contain(800, 800)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((res) => {
                setFormData({ ...formData, image: res });
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={userType === "CUSTOMER" ? 6 : 12}>
        <Dates setFormData={setFormData} formData={formData} error={error} />
      </Grid>
      {userType === "CUSTOMER" && (
        <Grid item xs={12} sm={userType === "CUSTOMER" ? 6 : 12}>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            select
            label="Sucursal a realizar el retiro"
            value={formData.localID}
            onChange={(e) =>
              setFormData({ ...formData, localID: e.target.value })
            }
            error={error && formData.localID === "EMPTY"}
          >
            <MenuItem value="EMPTY">
              <em>Selecciona una sucursal</em>
            </MenuItem>
            {utils.locals.map((local, key) => (
              <MenuItem value={local._id} key={key}>
                {local.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <TextField
          margin="dense"
          variant="outlined"
          label="Descripción del retiro"
          placeholder="Escribe aquí toda la información que consideres relevante para tu retiro. Qué es lo que se retira, sugerencias especiales, acceso al lugar, etc..."
          fullWidth
          rows={6}
          multiline
          InputLabelProps={{ shrink: true }}
          value={formData.description}
          error={error && !formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <div
          style={error && !formData.image ? { border: "solid 1px red" } : {}}
        >
          <DropzoneArea
            showPreviewsInDropzone={true}
            dropzoneText={
              "Arrastra la imagen del material o has click aquí para seleccionar una de tu librería"
            }
            classes={{ minHeight: 0 }}
            onChange={handleSetImages}
            acceptedFiles={["image/*"]}
            filesLimit={1}
            style={{ height: 100 }}
            showAlerts={false}
          />
        </div>
      </Grid>
    </Grid>
  );
}

function Dates({ error, setFormData, formData }) {
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    let aux = new Date();
    let temp = [];

    aux.setDate(aux.getDate() + 2);
    aux.setHours(0);
    aux.setMinutes(0);
    aux.setSeconds(1);

    for (let i = 0; i < 14; i++) {
      if (aux.getDay() !== 0) {
        temp.push({
          value: new Date(aux),
          option:
            days[aux.getDay()] +
            " " +
            aux.getDate() +
            " de " +
            months[aux.getMonth()] +
            " " +
            aux.getFullYear(),
        });
      }
      aux.setDate(aux.getDate() + 1);
    }
    setOptions(temp);
  }, []);

  const handleChange = (e) => {
    let aux = e.target.value;
    let temp = "";
    if (aux !== "0") {
      temp =
        days[aux.getDay()] +
        " " +
        aux.getDate() +
        " de " +
        months[aux.getMonth()] +
        " " +
        aux.getFullYear();
    }

    setFormData({ ...formData, datetimeRemoval: aux, dateString: temp });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          select
          label="Fecha"
          InputLabelProps={{ shrink: true }}
          value={formData.datetimeRemoval}
          onChange={handleChange}
          error={error && formData.datetimeRemoval === "0"}
          helperText={
            error && formData.datetimeRemoval === "0" && "Selecciona una fecha"
          }
        >
          <MenuItem value={"0"}>
            <em>Selecciona una fecha</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value + "_opcion"} value={option.value}>
              {option.option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="dense"
          variant="outlined"
          select
          label="Horario"
          InputLabelProps={{ shrink: true }}
          value={formData.window}
          onChange={(e) => setFormData({ ...formData, window: e.target.value })}
        >
          <MenuItem value="A">A (9:00 a 13:00)</MenuItem>
          <MenuItem value="B">B (14:00 a 19:00)</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
}

Dates.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

General.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
};
