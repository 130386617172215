import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Dialog, DialogContent, Typography, Grid } from "@material-ui/core";
import logo from "../../resources/images/logo.png";
import Fade from "react-reveal/Fade";
import { useAppContext } from "../../context/AppContext";
import useSound from "use-sound";
import initSound from "../../resources/audio/init_sound.wav";

const useStyles = makeStyles(({ breakpoints }) => ({
  welcomeLogo: {
    height: 180,
    [breakpoints.down("md")]: {
      height: 124,
      width: "100%",
    },
  },
}));

export default function DialogLogin() {
  const styles = useStyles();
  const { user } = useAppContext();
  const [play] = useSound(initSound);

  useEffect(() => play());

  return (
    <Dialog
      open={true}
      onClose={null}
      aria-labelledby="responsive-dialog-title"
      scroll={"body"}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent style={{ maxWidth: "100%" }}>
        <Fade>
          <Grid container style={{ maxWidth: "100%" }}>
            <Grid item align="center" xs={12}>
              <img
                className={styles.welcomeLogo}
                src={logo}
                style={{ marginBottom: 20 }}
                alt="Acción Circular"
              />
            </Grid>
            <Grid item align="center" xs={12}>
              <Typography variant="h3" align="center" style={{ color: "#fff" }}>
                Bienvenid@ {user?.name}
              </Typography>
              {!user?.activate && (
                <Typography
                  variant="h5"
                  align="center"
                  style={{ color: "#fff", marginTop: 16 }}
                >
                  Debes activar tu cuenta para ingresar
                </Typography>
              )}
            </Grid>
          </Grid>
        </Fade>
      </DialogContent>
    </Dialog>
  );
}
