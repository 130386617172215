import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  TextField, Typography,
  DialogActions,
  Stepper, Step, StepLabel,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import General from "./General";
import Disp from "./Disp";
import Vouc from "./Vouc";
import Vehicle from "./Vehicle";


import materialsList from "../../../../resources/materials";

import { useSelector, useDispatch } from "react-redux";



import removalsActions from "../../../../redux/actions/removals";
import Materials from "./Materials";

const style = {
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};



export default function Retiro({setViewRemoval, removal, formData, setFormData, view, setView, disabled, activeStep, setNextStepAllowed, textStepError}) {
  const utils = useSelector((state) => state.utils);
  
  const dispatch = useDispatch();
  const removals = useSelector((state) => state.removals);


  const [with_materials, setWith_materials] = useState();
  const list_materials = materialsList.materials;


  function _renderStepContent(step) {
	
    switch (step) {
        case 0:
        return <Vehicle disabled={disabled} formData={formData} setFormData={setFormData}  removal={removal}   setNextStepAllowed={setNextStepAllowed}   />;
        case 1:
          return <Materials disabled={disabled} formData={formData} setFormData={setFormData} with_materials={with_materials} setWith_materials={setWith_materials} setNextStepAllowed={setNextStepAllowed} />;
        
        case 2:
          return <Vouc disabled={disabled} formData={formData} setFormData={setFormData} setWith_materials={setWith_materials} />;
        case 3:
        
        return <Disp formData={formData} setFormData={setFormData}  removal={removal}  list_materials={list_materials} disabled={disabled} />;
        default:
        return <div>Not Found</div>;
    }
  }

  useEffect(() => {
    let temp = [];
    Object.keys(list_materials).forEach((material) => {
      
      temp[material.toUpperCase()] = 0;
    });
    setFormData({ ...formData, materials: temp });;
  }, []);

  // CUANDO ES ACTUALIZACIÓN
  useEffect(() => {

    
    
    const dataModal = removal;
    if (dataModal) {
      setView({ ...view, buttonName: "Actualizar" });
      var aux = {};
      var disp = [];
	  var notesdisp=[];
      var auxd = {};
      var materialsIcon = false;
      
      dataModal.materials.map((material) => {
        
        if(removal.statusTransport=="PENDING_VALID" || removal.statusTransport=="COMPLETED"){
          if(material.quantity>0){
            setWith_materials(true);
            materialsIcon = true;
          }
        }
        if(material.disposition!=null){
          disp[material.material] = material.disposition; 
		  notesdisp[material.material] = material.note;
		  
        }
      
        aux[material.material] = material.quantity;
        auxd[material.material] = material.disposition;
		
       
      });
      
      setFormData({
        ...dataModal,
        removalID: dataModal._id,
        localID: dataModal.localID._id,
        transporterID: dataModal.transporterID
          ? dataModal.transporterID._id
          : null,
        dispositionID: dataModal.dispositionID,
        vehicleID: dataModal.vehicleID,
        report: dataModal.urlReport,
        materialsIcon,
        materials: aux,
        dispositions: disp,
		text_list:notesdisp,
        
      });
    }
  }, [removals.dataModal]);


  return (
    <div>
      
          <General formData={formData} setFormData={setFormData} removal={removal} />
          
          <hr></hr>
		  
		   
      <Stepper activeStep={activeStep}>
        <Step completed={true}>
          <StepLabel>Vehículo</StepLabel>
        </Step>
        <Step completed={(activeStep==1 || removal.statusTransport==="PENDING_VALID")}>
          <StepLabel>Materiales</StepLabel>
        </Step>
        <Step completed={(activeStep==2 || removal.statusTransport==="PENDING_VALID")}>
          <StepLabel>Reporte y voucher</StepLabel>
        </Step>
        <Step completed={(activeStep==3 || removal.statusTransport==="PENDING_VALID")}>
          <StepLabel>Plantas de disposición</StepLabel>
        </Step>
  </Stepper>
  
  <React.Fragment>
  <Typography variant="body2" align="center" style={{ color: "red", marginBottom: 2 }}> {textStepError} </Typography>
    {_renderStepContent(activeStep)}      
  </React.Fragment>
          

        
    </div>
  )
}
