import cel from "./images/paper.png";
import plastic from "./images/plastic.png";
import metals from "./images/reciclables-02.png";
import organics from "./images/reciclables-02.png";

import glass from "./images/reciclables-02.png";
import aluminium from "./images/aluminium.png";
import tetrapak from "./images/reciclables-06.png";
import textils from "./images/fabrics.png";
import electronics from "./images/electronics.png";
import wood from "./images/wood.png";
import others from "./images/others.png";
import cel2 from "./images/CELULOSA.png";
import alu from "./images/ALUMINIO.png";
import pla from "./images/PLASTICO.png";
import ele from "./images/ELECTRONICOS.png";
import org from "./images/ORGANICOS.png";
import otr from "./images/OTROS.png";
import otrm from "./images/OTROSMETALES.png";
import VID from "./images/VIDRIO.png";
import TEX from "./images/TEXTILES.png";
import TET from "./images/TETRAPAK.png";
import MAD from "./images/MADERA.png";

import OIL from "./images/OIL.png";
import PAP from "./images/PAPEL.png";
import PET from "./images/PET.png";
import CAR from "./images/CARTON.png";
import FIL from "./images/PEL.png";
import AL from "./images/OIL.png";


const materials = {
  cel: {
    name: "Celulosa",
    key: "CEL",
    code: "20 01 38" ,
    typeResidue: "MADERA DISTINTA EN LA ESPECIFICADA EN EL CÓDIGO 20 01 37",
    color: "#45cafc",
    images: {
      light: cel2,
      dark: cel2,
    },
  },
  organics: {
    name: "Orgánicos",
    key: "ORGANICS",
    code: "20 01 08" ,
    typeResidue: "ORGANICOS",
    color: "#45cafc",
    images: {
      light: org,
      dark: org,
    },
  },
  aluminium: {
    name: "Aluminio",
    key: "ALUMINIUM",
    typeResidue: "METALES",
    code: "20 01 40" ,
    color: "#E74C3C",
    images: {
      light: alu,
      dark: alu,
    },
  },
  plastic: {
    name: "Plástico",
    key: "PLASTIC",
    typeResidue: "PLASTICOS",
    code : "20 01 39",
    color: "#FFBB28",
    images: {
      light: pla,
      dark: pla,
    },
  },
  tetrapak: {
    name: "Tetra pak",
    key: "TETRAPAK",
    typeResidue: "OTROS",
    code: "20 01 99",
    color: "#2471A3",
    images: {
      light: TET,
      dark: TET,
    },
  },
  metals: {
    name: "Otros metales",
    key: "METALS",
    color: "#85929E",
    typeResidue: "METALES",
    code: "20 01 40" ,
    images: {
      light: otrm,
      dark: otrm,
    },
  },
  electronics: {
    name: "Electrónicos",
    key: "ELECTRONICS",
    color: "#85929E",
    typeResidue: "EQUIPOS ELÉCTRICOS Y ELECTRÓNICOS DESECHADOS DE LOS ESPECIFICADOS EN LOS CÓDIGOS 20 01 21, 20 01 23 y 20 01 35",
    code: "20 01 36" ,
    images: {
      light: ele,
      dark: ele,
    },
  },
  textils: {
    name: "Textil",
    key: "TEXTILS",
    typeResidue: "TEXTIL",
    color: "#C39BD3",
    code: "20 01 10",
    images: {
      light: TEX,
      dark: TEX,
    },
  },
  glass: {
    name: "Vidrio",
    key: "GLASS",
    typeResidue: "VIDRIOS",
    color: "#2ECC71",
    code: "20 01 02",
    images: {
      light: VID,
      dark: VID,
    },
  },
  wood: {
    name: "Madera",
    key: "WOOD",
    code: "20 01 38",
    color: "#754e0f",
    typeResidue: "MADERA DISTINTA EN LA ESPECIFICADA EN EL CÓDIGO 20 01 37",
    images: {
      light: MAD,
      dark: MAD,
    }
  },
  paper: {
    name: "Papel",
    key: "PAPER",
    typeResidue: "PAPEL Y CARTON",
    code: "20 01 01" ,
    color: "#754e0f",
	images: {
      light: PAP,
      dark: PAP,
    }
  },
  paperboard: {
    name: "Cartón",
    key: "PAPERBOARD",
    typeResidue: "PAPEL Y CARTON",
    code: "20 01 01",
    color: "#754e0f",
	images: {
      light: CAR,
      dark: CAR,
    }
  },
  pet1: {
    name: "PET1",
    key: "PET1",
    typeResidue: "PLASTICOS",
    code: "20 01 99" ,
    color: "#754e0f",
	images: {
      light: PET,
      dark: PET,
    }
  },
  film: {
    name: "Film",
    key: "FILM",
    typeResidue: "PLASTICOS",
    code: "20 01 39" ,
    color: "#754e0f",
	images: {
      light: FIL,
      dark: FIL,
    }
  },
  oil: {
    name: "Aceite orgánico",
    key: "OIL",
    typeResidue: "ACEITES",
    color: "#754e0f",
    code: "20 01 25",
	images: {
      light: OIL,
      dark: OIL,
    }
  },
  ACEITE_LUBRICANTE: {
    name: "Aceite lubricante",
    key: "ACEITE_LUBRICANTE",
    typeResidue: "ACEITES",
    color: "#754e0f",
    code: "20 01 25",
	images: {
      light: OIL,
      dark: OIL,
    }
  },
  others: {
    name: "Otros",
    key: "OTHERS",
    typeResidue: "OTROS" ,
    color: "#eb4034",
    code: "20 01 99",
    images: {
      light: otr,
      dark: otr,
    }
  }
};

export default { materials };