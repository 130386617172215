export const materialTypes = [
  { name: "TREE", title: "Árboles" },
  { name: "WATER", title: "Agua" },
  { name: "PETROL", title: "Petróleo" },
  { name: "ENERGY", title: "Energía" },
  { name: "CO2", title: "CO2" },
];

export const parseFlagEcoeq = (flag) => {
  const material = materialTypes.find((element) => element.name === flag);
  return material ? material.title : `Material ${flag} invalido`;
};
