import React from "react";
import { Grid, TextField, Typography, Card } from "@material-ui/core";

export default function General({ transporter, removal }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">General</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          variant="outlined"
          label="Fecha y hora de retiro"
          value={new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }).format(new Date(removal.datetimeRemoval))}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          variant="outlined"
          label="Transportista"
          value={transporter?.name + " " + transporter?.lastName}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      {removal.transporterID?.rut &&
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            variant="outlined"
            label="Rut transportista"
            value={removal.transporterID?.rut}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>}
      {removal.vehicleID?.licensePlate &&
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            variant="outlined"
            label="Patente vehículo y tipo"
            value={removal.vehicleID?.licensePlate + "," + removal.vehicleID?.type}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      }
      {removal.dispositionID?.name &&
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            variant="outlined"
            label="Planta "
            value={removal.dispositionID?.name}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      }
      {removal.dispositionID?.rut &&
        <Grid item xs={12} sm={6}>
          <TextField
            margin="dense"
            variant="outlined"
            label="Rut planta"
            value={removal.dispositionID?.rut}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      }
      {removal.dispositionID?.typeOfTreatment &&
        <Grid item xs={12} sm={6}>
          <Grid item xs={12} >
            <Card style={{ padding: "15px" }}>
              <Typography >Tipo de tratamiento</Typography>
              <br/>
              {removal.dispositionID?.typeOfTreatment.map((element, i) => (
                <div key={`type_of_tratment${i}`}>
                  {element.name}
                </div>
              ))}
            </Card>
          </Grid>
        </Grid>
      }
    </Grid>
  );
}
