import React, { useEffect, useState } from "react";
import { TextField, Grid, MenuItem, Typography, Chip, formatMs } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { useSelector } from "react-redux";

import Jimp from "jimp";


const style = {
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};

export default function Vouc({ formData, setFormData, disabled }) {
  const utils = useSelector((state) => state.utils);
  const [fileValue, setFileValue] = useState("");
  const [pdfError, setPdfError] = useState(false);
  
  const handleDisp = ( matr, event) => {
    let temp = {
      ...formData.dispositions,
      [matr]: event.value
    };
    setFormData({
      ...formData,
      dispositions: temp,
    });
  };

  const onChangeDispNote = ( matr, event) => {
    let temp = {
      ...formData.text_list,
      [matr]: event.target.value
    };
    setFormData({
      ...formData,
      text_list: temp,
    });
  };

  const [autocompleteValues, setAutocompleteValues] = useState({
    local: "",
    transporter: "",
    disposition: "",
  });

  useEffect(() => {
    
    if (formData.localID || formData.transporterID || formData.dispositionID) {
      setAutocompleteValues({
        ...autocompleteValues,
        local: utils.locals.find((local) => local._id === formData.localID),
        disposition: utils.dispositions.dispositions.find((disposition) => disposition._id === formData.dispositionID),
      });
    }

  }, [formData.localID, formData.transporterID, formData.dispositionID]);


  const handleLoadFileVoucher = (files) => {
    console.log("handleLoadFileVoucher1");
    console.log(files);
    var list = [];
    Array.from(files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .contain(800, 800)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((res) => {
                list.push(res);
                
                // let response = Axios.post("/adminTool/removalsImages", { ...formData });
                // console.log(response);
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
    setFormData({ ...formData, urlVoucher: list });
  };

  
  function handleLoadFile(e) {
    var files = e.target.files;
    var file = null;
    if (files[0].type === "application/pdf") {
      let fileReader = new FileReader();
      setFileValue(e.target.value);
      setPdfError(false);
      fileReader.onload = (fileLoadedEvent) => {
        file = fileLoadedEvent.target.result;
        setFormData({ ...formData, file });
      };
      fileReader.readAsDataURL(files[0]);
    } else {
      setFileValue(null);
      setPdfError(true);
    }
  };
  
  

  return (
    <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justify="center"
        >
  <Grid item xs={12} sm={12}>
          {!disabled?
          <TextField
            {...style}
            label="Reporte"
            value={formData.report ? formData.report : fileValue}
            onChange={handleLoadFile}
            type={formData.report ? "text" : "file"}
            disabled={!formData.localID || !formData.transporterID}
            accept="pdf"
            error={pdfError}
            helperText={
              pdfError
                ? "Debe ser un archivo pdf. El archivo será descartado."
                : ""
            }
            onClick={formData.report ? () => window.open(formData.report) : null}
          />
          :<Typography variant="body2" align="center" color="textSecondary">Este retiro no tiene reporte</Typography>
        }
        </Grid>

        {!disabled?
        <Grid item xs={12} sm={12}>
          <DropzoneArea
            showPreviewsInDropzone={true}
            dropzoneText={
              "Fotos y voucher de retiro"
            }
            classes={{ minHeight: 0 }}
            onChange={handleLoadFileVoucher}
            acceptedFiles={["image/*"]}
            filesLimit={20}
            maxFileSize={20000000}
            style={{ height: 100 }}
            showAlerts={false}
          />
        </Grid>
      :""}
        <Grid item xs={12} sm={12}>
        {formData.urlVoucher ?
        <Grid item xs={12} sm={12}>
        {formData.urlVoucher.map((urlVoucher) => 
        <Grid item xs={12} sm={4}>
          <img
            src={urlVoucher}
            style={{ width: "100%" }}
            alt="voucher"
          />
      </Grid>
      )
      }
      </Grid>
      
        :
        !disabled?
        ""
        :<Typography variant="body2" align="center" color="textSecondary">Este retiro no tiene vouchers</Typography>

        } 
        </Grid>
        </Grid>
  );
}
