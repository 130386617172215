import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  makeStyles,
  withStyles,
  Button,
  ButtonGroup,
  Typography,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "datetimeRequest",
    numeric: false,
    disablePadding: false,
    label: "Fecha de solicitud",
  },
  {
    id: "vehicleID",
    numeric: false,
    disablePadding: false,
    label: "Patente y tipo",
  },
  {
    id: "datetimeRemoval",
    numeric: false,
    disablePadding: false,
    label: "Fecha de retiro",
  },
  {
    id: "kilos",
    numeric: false,
    disablePadding: false,
    label: "Kilos reciclados",
  },
  {
    id: "options",
    numeric: false,
    disablePadding: false,
    label: "Opciones",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  selected: {
    background:
      "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
    border: "white",
    color: "white",
  },
}));

export default function Removals({ setViewRemoval, setRemoval }) {
  const classes = useStyles();

  const removals = useSelector((state) => state.removals);

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("datetimeRequest");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setRows(removals.historic);
  }, [removals.historic]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const view = (data) => {
    setRemoval(data);
    setViewRemoval(true);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            size="small"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(index);

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <StyledTableCell align="left">
                        {new Intl.DateTimeFormat("es-CL", {
                          year: "numeric",
                          month: "long",
                          day: "2-digit",
                        }).format(new Date(row.datetimeRequest))}
                      </StyledTableCell>
                      
                      <StyledTableCell align="left">
                        {!row.vehicleID
                          ? "-"
                          : row.vehicleID?.licensePlate +
                          "," +
                          row.vehicleID?.type}
                      </StyledTableCell>
                      
                      <StyledTableCell align="left">
                        {new Intl.DateTimeFormat("es-CL", {
                          year: "numeric",
                          month: "long",
                          day: "2-digit",
                        }).format(new Date(row.datetimeRemoval))}
                      </StyledTableCell>
                      <StyledTableCell>
                        <NumberFormat
                          value={row.materials.reduce(
                            (ac, material) => ac + material.quantity * 1,
                            0
                          )}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          suffix={" Kg"}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <ButtonGroup>
                          <Button
                            className={classes.selected}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => view(row)}
                            fullWidth
                          >
                            Ver retiro
                          </Button>
                        </ButtonGroup>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * rows.length }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!rows.length && (
            <Typography variant="body1">
              No se encontraron resultados
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          labelRowsPerPage={"Filas por página"}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${count !== -1 ? count : `más de ${to}`
            }`
          }
          rowsPerPageOptions={[7, 14, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
