const defaultState = {
    list: [],
    localID: "EMPTY",
};

function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case "SENSORS_FETCH": {
            return { ...state, list: payload };
        }
        case "SENSORS_SET_LOCALID": {
            return { ...state, localID: payload.localID };
        }
        default:
            return state;
    }
}

export default reducer;