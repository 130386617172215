import React from "react";
import ReactMapGL, {
  FullscreenControl,
  ScaleControl,
  NavigationControl,
} from "react-map-gl";
import PropTypes from "prop-types";
import { useAppContext } from "../../../../context/AppContext";
import Description from "./Description";
import Controls from "./Controls";
const fullscreenControlStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: "10px",
};

const navStyle = {
  position: "absolute",
  top: 36,
  left: 0,
  padding: "10px",
};

const scaleControlStyle = {
  position: "absolute",
  bottom: 36,
  left: 0,
  padding: "10px",
};

const legend = {
  position: "absolute",
  bottom: 18,
  right: 0,
  padding: "10px",
};

// const mapStyleBottom = {
//   position: "absolute",
//   top: 0,
//   right: 0,
//   padding: "10px",
// };

function Map({ data }) {
  const { themeColor } = useAppContext();
  const [viewport, setViewport] = React.useState({
    width: "100%",
    height: 400,
    latitude: 0,
    longitude: 0,
    zoom: 13,
  });
  //data.sensors.sort((a,b)=>b.fill -a.fill);
  const [views, setViews] = React.useState({
    red: true,
    yellow: true,
    green: true,
  });

  React.useEffect(() => {
    if (data.length) {
      var latX = 0;
      var longX = 0;
      data.forEach((element) => {
        latX += element.position.latitude;
        longX += element.position.longitude;
      });
      latX = latX / data.length;
      longX = longX / data.length;
    }
    setViewport((v) => {
      return {
        ...v,
        latitude: latX,
        longitude: longX,
      };
    });
  }, [data]);

  return (
    <ReactMapGL
      mapboxApiAccessToken={
        "pk.eyJ1Ijoiam9zZXBlZHJhemEiLCJhIjoiY2sxZWpjMGcyMGprZjNjbXZvaXowMHJibCJ9.T2vE902XE8a_WVB3--0Ssg"
      }
      {...viewport}
      mapStyle={
        themeColor === "light"
          ? "mapbox://styles/mapbox/light-v10"
          : "mapbox://styles/mapbox/dark-v10"
      }
      onViewportChange={(viewport) => setViewport(viewport)}
      scrollZoom={true}
    >
      <div style={fullscreenControlStyle}>
        <FullscreenControl />
      </div>
      <div style={navStyle}>
        <NavigationControl />
      </div>
      <div style={scaleControlStyle}>
        <ScaleControl />
      </div>
      <div style={legend}>
        <Controls views={views} setViews={setViews} />
      </div>
      {/*Creacion de variables para el uso de llamados respectivos*/
        data.map((element, i) => {
        let payload = [];
        let payload2 = [];
        let temporal= []
        element.sensors.map((sensor,k)=>{
          const temp = sensor
          if (temp) {
            payload2.push({
              fill: sensor.fill,
              material: sensor.material
            });
          } else {
            payload2.push({
              fill: 0,
            });
          }
          temporal=payload2.sort((a,b)=>b.fill-a.fill)
        })
        for(let j = 0; j < data.length; j++){
          const temp = element.sensors[j];
          if (temp) {
            payload.push({
              fill: temporal[0].fill,
              material: temporal[0].material
            });
          } else {
            payload.push({
              fill: 0,
            });
          }
          if (
              (views.green && payload[0].fill <= 30) ||
              (views.yellow && payload[0].fill <= 60 && payload[0].fill >= 31) ||
              (views.red && payload[0].fill >= 61)
          ){ return <Description data={element} sensor={payload[0].fill} sensorOrder={temporal}key={"marker_" + i} />;
          } else {
            return <div key={"marker_" + i} />;
          }
        }})}
    </ReactMapGL>
  );
}

Map.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Map;
