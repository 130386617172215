import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import PropTypes from "prop-types";

const steps = [
  {
    name: "Solicitud",
    content: "Porcesando solicitud",
  },
  { name: "Transportista", content: "Buscando óptimo" },
  { name: "Recolección", content: "Ejecutando retiro" },
  { name: "Materiales", content: "Esperando reporte de reciclaje" },
];

export default function VerticalLinearStepper({ status }) {
  return (
    <Stepper activeStep={status} orientation="vertical">
      {steps.map((step, i) => (
        <Step key={i}>
          <StepLabel>{step.name}</StepLabel>
          <StepContent>{step.content}</StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

VerticalLinearStepper.propTypes = {
  status: PropTypes.number.isRequired,
};
