import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    type: "dark",

    primary: {
      main: "rgba(255, 255, 255, 0.9)",
    },
    secondary: {
      main: "#424242",
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 300,
    fontWeightMedium: 350,
    fontWeightBold: 400,
  },

  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: "#45cafc",
      },
    },
    // MuiAlert: {
    //   root: {
    //     fontSize: 18,
    //     fontWeight: 300,
    //   },
    //   filledSuccess: {
    //     background:
    //       "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(50,129,41,1) 29%, rgba(106,255,0,1) 92%)",
    //   },
    //   filledWarning: {
    //     background:
    //       "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(255,121,0,1) 29%, rgba(248,255,0,1) 92%)",
    //   },
    //   filledError: {
    //     background:
    //       "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(255,93,53,1) 29%, rgba(255,0,0,1) 92%)",
    //   },
    //   filledInfo: {
    //     background:
    //       "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
    //   },
    //   icon: {
    //     fontSize: 44,
    //   },
    // },
    MuiListItem: {
      root: {
        "&:hover": {
          backgroundColor: "#303030",
          color: "#fff",
        },
        "&$selected": {
          background:
            "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 3,
      },
    },
    MuiPaper: {
      root: {
        marginBottom: "4%",
      },
    },
  },
});
