import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useAppContext } from "../../../../../context/AppContext";
import PropTypes from "prop-types";
import materialsList from "../../../../../resources/materials";

const useStyles = makeStyles(() => ({
  imgDark: {
    filter: "invert(100%);",
    maxWidth: "100%",
  },
  imgLight: {
    maxWidth: "100%",
    opacity: 0.4,
  },
}));

export default function Materials({ formData, setFormData, error }) {
  const classes = useStyles();

  const { themeColor } = useAppContext();

  const toggeMaterial = (material) => {
    setFormData({
      ...formData,
      materials: {
        ...formData.materials,
        [material]: !formData.materials[material],
      },
    });
  };

  const materials = materialsList.materials;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!error ? (
          <Typography variant="body1">
            Selecciona los materiales que quieres retirar
          </Typography>
        ) : (
          <Typography variant="body1" style={{ color: "#ffbb33" }}>
            <ErrorOutlineIcon style={{ marginBottom: 4 }} /> Debes seleccionar
            mínimo un tipe de material
          </Typography>
        )}
      </Grid>
      {Object.keys(materials).map((key) => (
        <Grid item sm={2} xs={4} key={"material_modal_crear_" + key}>
          <Tooltip title={materials[key].name}>
            <Box boxShadow={3}>
              <Card
                style={{
                  border: formData.materials[key] ? "3px solid #303f9f" : "",
                }}
                onClick={() => toggeMaterial(key)}
              >
                <CardContent>
                  <img
                    src={materials[key].images["dark"]}
                    alt={materials[key].name}
                    className={
                      themeColor === "light"
                        ? classes.imgLight
                        : classes.imgDark
                    }
                  />
                </CardContent>
                <hr color={materials[key].color} style={{ height: 3 }} />
              </Card>
            </Box>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
}

Materials.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};
