import React from "react";
import NumberFormat from "react-number-format";
import { Grid, Box } from "@material-ui/core";
import DefaultCard from "../DefaultCard";
import removalImage from "../../../../resources/images/entrega.png";
import materialImage from "../../../../resources/images/basura.png";

function AdminCardSection2({ data, selectDate }) {
  const [removals, setRemovals] = React.useState("...");
  const [kilos, setKilos] = React.useState("...");

  React.useEffect(() => {
    if (data.removals && data.local) {
      setRemovals(
        data.removals.length +
          (!selectDate ? " / " + data.local?.suscriptionID?.removals : "")
      );
    }
    if (data.totalMaterials) {
      setKilos(
        data.totalMaterials.length ? (
          <NumberFormat
            value={data.totalMaterials.reduce(
              (ac, material) => ac + material.quantity * 1,
              0
            )}
            displayType={"text"}
            thousandSeparator={true}
            suffix={" Kg"}
          />
        ) : (
          0
        )
      );
    }
  }, [data, selectDate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} sm={6}>
        <Box boxShadow={3}>
          <DefaultCard
            value={removals}
            image={removalImage}
            background={
              "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(255,121,0,1) 29%, rgba(248,255,0,1) 92%)"
            }
            title="RETIROS COMPLETADOS"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4} sm={6}>
        <Box boxShadow={2}>
          <DefaultCard
            value={kilos}
            image={materialImage}
            background={
              "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(50,129,41,1) 29%, rgba(106,255,0,1) 92%)"
            }
            title="MATERIAL RECICLADO"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
export default AdminCardSection2;
