import React from "react";

export default function HrColor() {
  return (
    <hr
      color="#0099CC"
      style={{
        background:
          "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
        height: 2,
        marginBottom: 24,
      }}
    />
  );
}
