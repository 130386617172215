import React from "react";
import { Grid } from "@material-ui/core";
import { useAppContext } from "../../../../../context/AppContext";
import SuscriptionCard from "./SuscriptionCard";

export default function Admin() {
  const { userData } = useAppContext();
  const [suscription, setSuscription] = React.useState({});

  React.useEffect(() => {
    if (userData.suscriptionID) {
      setSuscription(userData.suscriptionID);
    }
  }, [userData]);

  return (
    <Grid item xs={12}>
      <SuscriptionCard suscription={suscription} />
    </Grid>
  );
}
