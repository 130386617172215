import React from 'react'
import DialogLogin from './DialogLogin'
import LoginForm from './LoginForm'

import { useAppContext } from '../../context/AppContext'

export default function Login () {
  const { login, loginStatus } = useAppContext()
  return (
    <div>
      {loginStatus.step !== 'LOAD' ? (
        <LoginForm login={login} />
      ) : (
        <DialogLogin />
      )}
    </div>
  )
}
