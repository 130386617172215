import React from "react";
import ReactDOM from "react-dom";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import "mdbreact/dist/css/mdb.css";
import "fontsource-roboto";
// import store from "./redux/store";
import { Provider } from "react-redux";

import App from "./App";

import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter as Router } from "react-router-dom";

import configureStore, { history } from "./redux/store";
const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
