import React from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@material-ui/core";
import { parseFlag } from "../../../../resources/residuesTypes";

export default function TableMaterials({ materials }) {
  return (
    <div>
      <Typography variant="h6">Cantidad de material</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Material</TableCell>
            <TableCell>Kilos</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {materials.map((material, i) => (
            <TableRow key={i}>
              <TableCell>{parseFlag(material.material)}</TableCell>
              <TableCell>{material.quantity} Kg</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
