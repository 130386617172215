import React from "react";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { useAppContext } from "../../../../context/AppContext";
import { useSensorsContext } from "../../../../context/SensorsContext";
import materialsList from "../../../../resources/materials";
import SwitchButton from "./Switch";

const style = {
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};

// const timeWindowsConstant = [
//   {
//     nameT: "A: 9:00 a 13:00",
//     value: "A",
//   },
//   {
//     nameT: "B: 14:00 a 19:00",
//     value: "B",
//   },
// ];

// const dayWindowsConstant = [
//   {
//     nameD: "Lunes",
//     value: "LUN",
//   },
//   {
//     nameD: "Martes",
//     value: "MAR",
//   },
//   {
//     nameD: "Miércoles",
//     value: "MIE",
//   },
//   {
//     nameD: "Jueves",
//     value: "JUE",
//   },
//   {
//     nameD: "Viernes",
//     value: "VIE",
//   },
//   {
//     nameD: "Sábado",
//     value: "SAB",
//   },
// ];

export default function ViewSensors() {
  const { userType, entityID } = useAppContext();
  const { locals, getSensorsConfigureByLocal } = useSensorsContext();
  
  const [data, setData] = React.useState([])
  const [local, setLocal] = React.useState("EMPTY");
  // const [timeWindows, setTimeWindows] = React.useState([]);
  // const [dayWindows, setDayWindows] = React.useState([]);
  // const [general, setGeneral] = React.useState({
  //   maxRemovalsMonthly: 30,
  //   maxRemovalsDaily: 1,
  //   emailConfirm: true,
  // });
  const materials = materialsList.materials;

  React.useEffect(() => {
    if (userType === "LOCAL") {
      setLocal(entityID);
    }
  }, []);

  React.useEffect(() => {
    if (local) {
      getSensorsConfigureByLocal({ localID: local }).then((response) => {
        setData(response);
      });
    }
  }, [getSensorsConfigureByLocal, local]);

  // const handleChange = async (name, value, i) => {
  //   if (name === "containerHeight") {
  //     if (value < 0) {
  //       value = 0;
  //     } else if (value > 200) {
  //       value = 200;
  //     }
  //   }
  //   let aux = data;
  //   aux[i] = { ...data[i], [name]: value };
  //   setData([...aux]);
  // };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          {userType === "CUSTOMER" && (
            <>
              <Select
                margin="dense"
                variant="outlined"
                fullWidth={true}
                value={local}
                onChange={(e) => setLocal(e.target.value)}
              >
                <MenuItem selected value={"EMPTY"}>
                  <em>Selecciona una sede / sucursal</em>
                </MenuItem>
                {locals.map((el, i) => (
                  <MenuItem key={"option_local" + i} value={el._id}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <hr></hr>
        </Grid>
      </Grid>
      <br />
      {(local !== "EMPTY" || userType === "LOCAL") && (
        <Grid container spacing={2}>
          {/* <Grid item xs={3}>
            <TextField
              {...style}
              disabled
              label="Máximo de retiros mensuales"
              value={general.maxRemovalsMonthly}
              helperText="0 para retiros ilimitados"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              {...style}
              disabled
              label="Máximo de retiros diarios"
              value={general.maxRemovalsDaily}
              helperText="0 para retiros ilimitados"
            />
          </Grid>
          <Grid item xs={3} align="center">
            <div
              style={{
                borderRadius: 4,
                padding: 6,
                border: `solid 1px #e0e0e0`,
              }}
            >
              <Typography variant="body2" color="textSecondary" align="justify">
                Enviar correo de confirmación antes de solicitar un retiro
              </Typography>

              <SwitchButton
                alert={false}
                value={false}
                onChange={(e) =>
                  setGeneral({ ...general, emailConfirm: e.target.checked })
                }
                label={general.emailConfirm ? "Sí" : "No"}
              />
            </div>
          </Grid> */}
          {/* <Grid item xs={4}>
                    <TextField
                        {...style}
                        label="Día para solicitud de retiros"
                        value={""}
                        disabled
                    />
          </Grid>
          <Grid xs={4}/>
          <Grid item xs={4}>
                    <TextField
                        {...style}
                        label="Hora para solicitud de retiros"
                        value={""}
                        disabled
                    />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="body1">
              Panel de configuración de solicitud de retiros de sensores
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>IMEI</TableCell>
                  <TableCell>Material</TableCell>
                  <TableCell>Altura del contenedor</TableCell>
                  <TableCell>Límite de llenado</TableCell>
                  <TableCell>Esperar a los demás sensores</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((sensor, i) => (
                  <TableRow key={"fila_" + i}>
                    <TableCell>{sensor.imei}</TableCell>
                    <TableCell>
                      <Select
                        margin="dense"
                        variant="outlined"
                        fullWidth={true}
                        disabled
                        value={sensor.material}
                      >
                        {Object.keys(materials).map((material) => (
                          <MenuItem
                            key={"material_" + materials[material].key.toUpperCase()}
                            value={materials[material].key.toUpperCase()}
                          >
                            {materials[material].name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        {...style}
                        disabled
                        InputProps={{
                          endAdornment: <InputAdornment>cm</InputAdornment>,
                        }}
                        value={sensor.containerHeight}
                        type="number"
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        margin="dense"
                        variant="outlined"
                        fullWidth={true}
                        disabled
                        value={sensor.fillLimitPercentage}
                      >
                        <MenuItem value={50}>50%</MenuItem>
                        <MenuItem value={60}>60%</MenuItem>
                        <MenuItem value={70}>70%</MenuItem>
                        <MenuItem value={80}>80%</MenuItem>
                        <MenuItem value={90}>90%</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <SwitchButton
                        value={false}
                        label={
                          sensor.waitOthersSensors ? "Esperar" : "No esperar"
                        }
                        alert={true}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      )}
    </>
  );
}
