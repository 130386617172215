import React from "react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Grow,
  Typography,
  MenuItem,
} from "@material-ui/core";
import Header from "../../Navigation/Header";
import ReportCardCustomer from "./ReportCardCustomer";
import ReportCardSinader from "./ReportCardSinader";
import ReportCardLocal from "./ReportCardLocal";
import { Assignment } from "@material-ui/icons";

import { useDispatch, useSelector } from "react-redux";
import reportActions from "../../../redux/actions/reports";
import { useAppContext } from "../../../context/AppContext";

function Index() {
  const dispatch = useDispatch();
  const { userType , entityID } = useAppContext();

  const reports = useSelector((state) => state.reports);
  const utils = useSelector((state) => state.utils);

  const [localID, setLocalID] = React.useState("ALL");

  React.useEffect(() => {
    if (userType === "LOCAL") {
      setLocalID(entityID);
    }
  }, []);

  React.useEffect(() => {
    const fetch = async () => {
      dispatch(reportActions.fetch({ localID }));
    };
    fetch();
  }, [localID]);

  return (
    <Card>
      <CardContent>
        <Header
          title={
            <Typography component="h2" variant="h4">
              <Assignment style={{ fontSize: 34, marginBottom: 6 }} /> Tus
              reportes
            </Typography>
          }
        />
        <Grid container>
          <Grid item xs={12}>
            <hr></hr>
          </Grid>
          {userType === "CUSTOMER" && (
            <Grid item xs={12} sm={4} md={4}>
              <TextField
                margin="dense"
                variant="outlined"
                label="Sucursales"
                InputLabelProps={{ shrink: true }}
                select
                value={localID}
                onChange={(e) => setLocalID(e.target.value)}
                fullWidth
              >
                <MenuItem value="ALL">
                  <em>Todos</em>
                </MenuItem>
                {utils.locals.map((local) => (
                  <MenuItem value={local._id} key={"local_" + local._id}>
                    {local.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
		  {/* <Grid item xs={12}>
            {userType === "CUSTOMER" && <hr />}
            <Typography component="h4" variant="h6" style={{ marginBottom: 12 }}>
              <Assignment style={{ fontSize: 22, marginBottom: 4 }} /> 
              Reporte SINADER (.xls)
            </Typography>
            <Grid container spacing={3}>
              {utils.locals.map((local, i) => (
                <Grow key={i} in={true} timeout={500 + 500 * i}>
                  <Grid item xs={12} sm={3} key={i}>
                    {userType === "CUSTOMER" ? (
                      <ReportCardSinader local={local} />
                    ) : (
                      <ReportCardSinader local={local} />
                    )}
                  </Grid>
                </Grow>
              ))}
            </Grid>
		   </Grid>*/}
          <Grid item xs={12}>
            {userType === "CUSTOMER" && <hr />}
            <Typography component="h4" variant="h6" style={{ marginBottom: 12 }}>
              <Assignment style={{ fontSize: 22, marginBottom: 4 }} /> 
              Reportes (.pdf)
            </Typography>
            <Grid container spacing={3}>
              {reports.reports.map((report, i) => (
                <Grow key={i} in={true} timeout={500 + 500 * i}>
                  <Grid item xs={12} sm={3} key={i}>
                    {userType === "CUSTOMER" ? (
                      <ReportCardCustomer report={report} />
                    ) : (
                      <ReportCardLocal report={report} />
                    )}
                  </Grid>
                </Grow>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Index;
