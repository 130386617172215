import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import PropTypes from "prop-types";
import removalActions from "../../../../redux/actions/removals";
import { useDispatch } from "react-redux";

export default function RemovalCard({ removal, ratingTemp, setRatingTemp }) {
  const dispatch = useDispatch();

  const onChangeRating = (e) => {
    const value = e.target.value;
    setRatingTemp(value);
    setTimeout(() => {
      dispatch(removalActions.setRating(value, removal._id));
      dispatch(removalActions.fetchCurrent());
    }, 3000);
  };

  return (
    <Grid item container direction="row" justify="center" alignItems="center">
      <Typography variant="body1">Por favor valora el retiro</Typography>
      <Grid item>
        <Rating
          name="size-large"
          value={ratingTemp}
          onChange={onChangeRating}
          size="large"
        />
      </Grid>
      {ratingTemp !== 0 && (
        <Typography variant="h5" align="center" style={{ color: "#ffb400" }}>
          <strong>Gracias!</strong>
        </Typography>
      )}
    </Grid>
  );
}

RemovalCard.propTypes = {
  removal: PropTypes.object.isRequired,
  ratingTemp: PropTypes.number.isRequired,
  setRatingTemp: PropTypes.func.isRequired,
};
