import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  Grid,
  TextField,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import Jimp from "jimp";


import { useDispatch } from "react-redux";
import { useAppContext } from "../../../../context/AppContext";
import removalActions from "../../../../redux/actions/removals";

export default function CoordinateSchedulerModal({
  coordinateRemovalScheduler,
  datetimeRemoval,
  setCoordinateRemovalScheduler,
  localID,
}) {
  const dispatch = useDispatch();
  const { userType, entityID } = useAppContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [error, setError] = React.useState(false);
  const [formData, setFormData] = useState({
    datetimeRemoval: "0",
    window: "A",
    description: "",
    image: "",
  });

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  const handleSetImages = (event) => {
    Array.from(event.target.files).forEach((file) => {
      setTimeout(() => {
        Jimp.read(URL.createObjectURL(file))
          .then((photo) => {
            photo
              .contain(800, 800)
              .quality(100)
              .getBase64Async(Jimp.MIME_JPEG)
              .then((res) => {
                setFormData({ ...formData, image: res });
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1000);
    });
  };

  const handleClose = () => {
    setCoordinateRemovalScheduler(false);
  };

  const success = async () => {
    formData.datetimeRemoval = datetimeRemoval;
    if (formData.datetimeRemoval !== "0") {
      await dispatch(removalActions.requestRemoval({ formData, userType, entityID, localID }));
      setCoordinateRemovalScheduler(false);
    } else {
      setError(true);
    }
  };

  const daySelected = () => {
    return datetimeRemoval.format("DD");
  }
  const currentMonthName = () => {
    return datetimeRemoval.format("MMMM");
  }

  return (
    <Dialog
      open={coordinateRemovalScheduler}
      aria-labelledby="form-dialog-title"
      fullScreen={isMobile}
      scroll="body"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">¿ Desea agendar el retiro para el día  {daySelected()} de {currentMonthName()} ?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          De confirmar, su retiro quedará agendado y no podrá ser cancelado antes de las 48 horas previas a su ejecución.
      </DialogContentText>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none", width: "100%" }}
                id="raised-button-file-2"
                type="file"
                onChange={(event) => handleSetImages(event)}
              />
              <label htmlFor="raised-button-file-2" style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  fullWidth
                  startIcon={<CloudUploadOutlinedIcon />}
                  style={{
                    marginTop: 8,
                    height: 40,
                  }}
                >
                  subir foto (opcional)
              </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              {formData.image && (
                <img src={formData.image} style={{ width: 200, display: "block", margin: "auto" }} alt="imagen_retiro" />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                variant="outlined"
                label="Descripción del retiro (opcional)"
                fullWidth
                rows={4}
                placeholder="Escribe aquí toda la información que consideres relevante para tu retiro. Qué es lo que se retira, sugerencias especiales, acceso al lugar, etc..."
                multiline
                InputLabelProps={{ shrink: true }}
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
      </Button>
        <Button onClick={() => success()} color="primary">
          Agendar
      </Button>
      </DialogActions>
    </Dialog>
  );
}