import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { PulseLoader } from "react-spinners";
import PropTypes from "prop-types";
import { useAppContext } from "../../../../context/AppContext";

const useStyles = makeStyles(() => ({
  imgDark: {
    filter: "invert(100%);",
    height: 60,
  },
  imgLight: {
    height: 60,
  },
}));

export default function EcoEq({ data }) {
  const classes = useStyles();
  const { themeColor } = useAppContext();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setHover(false);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const [hover, setHover] = React.useState(false);
  return (
    <Grid
      item
      md={2}
      sm={4}
      xs={6}
      style={{ opacity: hover ? 0.7 : 1 }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      align="center"
    >
      <img
        src={themeColor === "light" ? data.image.light : data.image.dark}
        className={themeColor === "light" ? classes.imgLight : classes.imgDark}
        style={{
          opacity: hover ? 0.8 : 1,
        }}
        alt={data.alt}
      />
      <Typography variant="h6" align="center" color="textSecondary">
        {data.value === -1 ? (
          <PulseLoader color="#303f9f" size={10} loading={true} />
        ) : (
          <>
            <NumberFormat
              value={data.value}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
            />{" "}
            {data.unity}
          </>
        )}
      </Typography>
      <Typography variant="body1" align="center" color="textSecondary">
        {data.subtitle}
      </Typography>
    </Grid>
  );
}

EcoEq.propTypes = {
  data: PropTypes.object.isRequired,
};
