import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

import CardMaterial from "./CardMaterial";
import Chart from "./Chart";
import TableMaterials from "./TableMaterials";
import General from "./General";
import Description from "./Description";
import Voucher from "./Voucher";

export default function Modal({ setViewRemoval, removal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [materials, setMaterials] = React.useState([]);
  const [transporter, setTransporter] = React.useState([]);
  const [totalMaterial, setTotalMaterial] = React.useState(0);

  React.useEffect(() => {
    if (removal) {
      setMaterials(removal.materials);
      setTransporter(removal.transporterID);
      setTotalMaterial(
        <NumberFormat
          value={removal.materials?.reduce(
            (ac, material) => ac + material.quantity * 1,
            0
          )}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          suffix={" Kg"}
        />
      );
    }
  }, [removal]);

  return (
    <Dialog
      open={true}
      onClose={() => setViewRemoval(false)}
      maxWidth="md"
      fullWidth
      scroll={"body"}
      fullScreen={isMobile}
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">Ver retiro</Typography>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={() => setViewRemoval(false)}>
              <ClearOutlinedIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <hr></hr>
          </Grid>
        </Grid>

        {isMobile ? (
          <Grid container spacing={2}>
            <Grid xs={12}>
              <CardMaterial value={totalMaterial} />
              <hr />
            </Grid>
            <Grid xs={12}>
              <General removal={removal} transporter={transporter} />
              <hr />
            </Grid>
            <Grid xs={12}>
              <TableMaterials materials={materials} />
              <hr />
            </Grid>
            <Grid xs={12}>
              <Chart materials={materials} totalMaterial={totalMaterial} />
            </Grid>
            {removal.urlVoucher &&
              <Grid item xs={12}>
                <Voucher removal={removal} />
              </Grid>
            }
          </Grid>
        ) : (
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={6}>
              <General removal={removal} transporter={transporter} />
            </Grid>
            <Grid item xs={6}>
              <CardMaterial value={totalMaterial} />
            </Grid>
            <Grid item xs={6}>
              <TableMaterials materials={materials} />
            </Grid>
            <Grid item xs={6}>
              <Chart materials={materials} totalMaterial={totalMaterial} />
            </Grid>
            {removal.notes &&
              <Grid item xs={12}>
                <Description removal={removal} />
              </Grid>
            }
            {removal.urlVoucher &&
              <Grid item xs={6}>
                <Voucher removal={removal} />
              </Grid>
            }
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
