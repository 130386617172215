import React, {useState} from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

import CardMaterial from "./CardMaterial";
import Chart from "./Chart";
import TableMaterials from "./TableMaterials";
import General from "./General";
import { ButtonGroupAction, ButtonSwitch, Notes } from "./Components";
import Description from "./Description";
import Voucher from "./Voucher";

import { useAppContext } from "../../../../context/AppContext";
import removalsActions from "../../../../redux/actions/removals";

import Retiro from "./Retiro";
import materialsList from "../../../../resources/materials";

export default function Modal({ setViewRemoval, removal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const now = new Date();
  
  const dispatch = useDispatch(); // Para enviar retiro y WS
  const [transporter, setTransporter] = React.useState([]); //Esto se saca
  const [materials, setMaterials] = React.useState([]); //Esto se saca
  
  
  
  const [totalMaterial, setTotalMaterial] = React.useState(0);
  const [nextStepAllowed, setNextStepAllowed] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const { userType, userID  } = useAppContext();

  const [formData, setFormData] = useState({
    _id: removal._id,  
    userID: userID,
    statusTransport: "PENDING_VALID",
    materials: null,
    dispositions: [],
    text_list: [],
  });
  

  const [view, setView] = useState({
    notes: false,
    materials: false,
    buttonName: "crear",
    alert: false,
  });

  const list_materials = materialsList.materials;

  
  const [sendButtonEnabled, setSendButtonEnabled] = useState(true);

  const timeout = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleSuccess = async () => {
    setView({ ...view, alert: true });
    await timeout(2000);
      // convertir obejeto de materiales a arreglo de modelo de backend
      let materials = Object.keys(formData.materials).map((key) => {
        //console.log(formData.text_list);
		
        return {
          
          material: key,
          disposition: !formData.dispositions[key] ? null : formData.dispositions[key],
          note: !formData.text_list[key] ? null : formData.text_list[key],
          code: list_materials[key.toLowerCase()].code,
          typeResidue: list_materials[key.toLowerCase()].name,
          residueName: list_materials[key.toLowerCase()].typeResidue,
          quantity: formData.materials[key],  
        };
      });
      const response = await dispatch(
        removalsActions.sendRemovalTran({
          _id: formData.removalID,
          ...formData,
          includeMaterial: 1,
          statusTransport : "PENDING_VALID",
          materials,
        })
      );
      if (response) {
       console.log("entra");
        //dispatch(removalsActions.setSelector("COMPLETE"));
        setViewRemoval(false);
        //dispatch(removalsActions.setDataModal(null));
        //dispatch(removalsActions.setCreateModal(false));
        
      }
    
    setView({ ...view, alert: false });
  };

  React.useEffect(() => {
    setDisabled((removal.statusTransport=="PENDING_VALID" || removal.statusTransport=="COMPLETED" || removal.statusTransport=="REJECTED" )  && userType === "TRANSPORTER");
    if (removal) {
      setFormData({
        removalID: removal._id,
        localID: removal.localID._id,
        transporterID: removal.transporterID
          ? removal.transporterID._id
          : null,
        dispositionID: removal.dispositionID,
        vehicleID: removal.vehicleID,
        report: removal.urlReport,
        dispositions: [],
        text_list: [],
        materials: removal.materials,
      });
      
      if ((removal.statusTransport=="PENDING_VALID" && userType === "TRANSPORTER")||(removal.statusTransport=="REJECTED" && userType === "TRANSPORTER")||(removal.statusTransport=="COMPLETED" && userType === "TRANSPORTER") ) {
        
        setSendButtonEnabled(false)
      }

      setTotalMaterial(
        <NumberFormat
          value={removal.materials?.reduce(
            (ac, material) => ac + material.quantity * 1,
            0
          )}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          suffix={" Kg"}
        />
      );
    }
	
  }, [removal]);

  const handleClose = () => {
    setViewRemoval(false);
  };

  const steps = ['Shipping address', 'Payment details', 'Review your order', 'Review your order'];

  const [activeStep, setActiveStep] = useState(0);
  const [textStepError, setTextStepError] = useState(null);
  const isLastStep = activeStep === steps.length - 1;
  
  function _handleBack() {
    setActiveStep(activeStep - 1);
  }
  

  const _handleNext = async () => {
   
    if (nextStepAllowed || disabled){
      setActiveStep(activeStep +1);
      setTextStepError(null);
    }else{
      switch(activeStep){
        case 0:
          //dispatch(removalsActions.removalVehicleidNull());
          setTextStepError("Debe ingresar un vehículo");
          break;
        case 1:
          //dispatch(removalsActions.removalMaterialsNull());
          setTextStepError("Debe indicar si hubo material");
          break;
      }
    }
      
  }

  return (
    userType === "TRANSPORTER" ? 
    <Dialog
      open={true}
      onClose={() => setViewRemoval(false)}
      maxWidth="md"
      fullWidth
      scroll={"body"}
      fullScreen={isMobile}
    >

    <DialogTitle id="form-dialog-title">Retiro</DialogTitle>
      <DialogContent>
        <Retiro removal={removal} setNextStepAllowed={setNextStepAllowed} view={view} formData={formData} setFormData={setFormData} activeStep={activeStep} setView={setView} disabled={disabled} textStepError={textStepError}/>
      </DialogContent>

      <DialogActions>
          <ButtonGroupAction
            handleClose={handleClose}
            handleSuccess={handleSuccess}
            isLastStep={isLastStep}
            _handleBack={_handleBack}
            _handleNext={_handleNext}
            view={view}
            sendButtonEnabled={sendButtonEnabled}
            activeStep={activeStep}
            formData={formData}
          />
        </DialogActions>


    </Dialog>

    :

    <Dialog
      open={true}
      onClose={() => setViewRemoval(false)}
      maxWidth="md"
      fullWidth
      scroll={"body"}
      fullScreen={isMobile}
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">Ver retiro</Typography>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={() => setViewRemoval(false)}>
              <ClearOutlinedIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <hr></hr>
          </Grid>
        </Grid>

        {isMobile ? (
          <Grid container spacing={2}>
            <Grid xs={12}>
              <CardMaterial value={totalMaterial} />
              <hr />
            </Grid>
            <Grid xs={12}>
              <General removal={removal} transporter={transporter} />
              <hr />
            </Grid>
            <Grid xs={12}>
              <TableMaterials materials={materials} />
              <hr />
            </Grid>
            <Grid xs={12}>
              <Chart materials={materials} totalMaterial={totalMaterial} />
            </Grid>
            {removal.urlVoucher &&
              <Grid item xs={12}>
                <Voucher removal={removal} />
              </Grid>
            }
          </Grid>
        ) : (
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={6}>
              <General removal={removal} transporter={transporter} />
            </Grid>
            <Grid item xs={6}>
              <CardMaterial value={totalMaterial} />
            </Grid>
            <Grid item xs={6}>
              <TableMaterials materials={materials} />
            </Grid>
            <Grid item xs={6}>
              <Chart materials={materials} totalMaterial={totalMaterial} />
            </Grid>
            {removal.notes &&
              <Grid item xs={12}>
                <Description removal={removal} />
              </Grid>
            }
            {removal.urlVoucher &&
              <Grid item xs={6}>
                <Voucher removal={removal} />
              </Grid>
            }
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
