import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    type: "light",

    primary: {
      main: "#303f9f",
    },
    secondary: {
      main: "#303f9f",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 450,
    fontWeightBold: 500,
    rojo: {
      color: "#f00",
    }
  },
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: "#45cafc",
      },
    },
    MuiAlert: {
      // root: {
      //   fontSize: 18,
      //   fontWeight: 300,
      // },
      filledSuccess: {
        background:
          "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(50,129,41,1) 29%, rgba(106,255,0,1) 92%)",
      },
      filledWarning: {
        background:
          "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(255,121,0,1) 29%, rgba(248,255,0,1) 92%)",
      },
      filledError: {
        background:
          "linear-gradient(34deg, rgba(43,43,43,1) 0%, rgba(255,93,53,1) 29%, rgba(255,0,0,1) 92%)",
      },
      filledInfo: {
        background:
          "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
      },
      // icon: {
      //   fontSize: 30,
      // },
    },
    MuiListItem: {
      root: {
        "&:hover": {
          backgroundColor: "#c7c7c7",
          color: "#000",
        },
        "&$selected": {
          background:
            "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
          color: "#fff",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        height: 3,
      },
    },
    MuiPaper: {
      root: {
        marginBottom: "4%",
      },
    },
  },
});
