import React from "react";
import {
  useProfileContext,
  ProfileContextProvider,
} from "../../context/ProfileContext";
import { useAppContext } from "../../context/AppContext";
import { Grid, Button, Avatar, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 256,
    textAlign: "center",
    padding: 0,
    margin: 0,
  },
  avatar: {
    width: 100,
    height: 100,
    margin: "auto",
  },
}));

const ProfileCard = () => {
  const styles = useStyles();
  const { profileData } = useProfileContext();
  const { logout } = useAppContext();
  const [hover, setHover] = React.useState(false);
  return (
    <div className={styles.card}>
      <br></br>
      <div>
        <Avatar
          className={styles.avatar}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          style={{
            border: hover ? "2px solid #303f9f" : "2px solid #fff",
            opacity: hover ? 0.5 : 1,
          }}
          src={"https://image.flaticon.com/icons/svg/747/747376.svg"}
        />
        {hover && <p>cambiar imagen</p>}
      </div>

      <br></br>
      <Typography variant="h5">
        {profileData.userName + " " + profileData.userLastName}
      </Typography>
      <Typography variant="body2" style={{ marginBottom: 6 }}>
        {profileData.userEmail}
      </Typography>
      <Divider light />
      <Grid
        container
        style={{
          padding: 10,
        }}
      >
        <Grid item xs={12}>
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <Button startIcon={<PersonIcon />} fullWidth>
              Perfil
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<ExitToAppIcon />}
            fullWidth
            onClick={() => logout()}
          >
            Cerrar sesión
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default () => (
  <ProfileContextProvider>
    <ProfileCard />
  </ProfileContextProvider>
);
