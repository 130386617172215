import React from "react";
import { Grid, TextField, makeStyles, InputAdornment } from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";

const useStyles = makeStyles({
  root: {
    border: "2px #45cafc",
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `#303f9f !important`,
    },
  },
  notchedOutline: {
    borderWidth: "2px",
    borderColor: "#303f9f !important",
  },
});

const TextFieldStyled = ({
  value,
  name,
  error,
  label,
  onChange,
  adornment,
  edit,
  disabled,
}) => {
  const styles = useStyles();
  return (
    <TextField
      margin="dense"
      variant="outlined"
      fullWidth
      name={name}
      label={label}
      value={value}
      error={!value && error}
      onChange={onChange}
      disabled={disabled}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={
        edit && !error
          ? {
              classes: {
                root: styles.cssOutlinedInput,
                notchedOutline: styles.notchedOutline,
                focused: styles.focused,
              },
              readOnly: false,
              startAdornment: (
                <InputAdornment position="start">{adornment}</InputAdornment>
              ),
            }
          : {
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">{adornment}</InputAdornment>
              ),
            }
      }
    />
  );
};

export default function UserDataForm({ error, formData, setFormData, edit }) {
  const updateData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formatPhone = (phone) => {
    phone = String(phone).replace(/\D/g, "");
    let aux = "";
    for (let i = 0; i < phone.length; i++) {
      if (i === 0) {
        aux = "+";
      }
      if (i === 2 || i === 3 || i === 7 || i === 11) {
        aux += " ";
      }
      aux += phone[i];
      if (i === 10) {
        break;
      }
    }
    return aux;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <hr></hr>
        <h4>Usuario de sucursal</h4>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldStyled
          value={formData.userName}
          name="userName"
          label="Nombre"
          error={error}
          edit={false}
          disabled={true}
          onChange={updateData}
          adornment={<AccountCircleIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldStyled
          value={formData.userLastName}
          name="userLastName"
          label="Apellido"
          error={error}
          edit={false}
          disabled={true}
          onChange={updateData}
          adornment={<AccountCircleIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldStyled
          value={formatPhone(formData.userPhone)}
          name="userPhone"
          label="Teléfono"
          error={error}
          edit={edit}
          onChange={updateData}
          adornment={<PhoneIphoneIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldStyled
          value={formData.userEmail}
          name="userEmail"
          label="Email"
          error={error}
          edit={false}
          disabled={true}
          onChange={updateData}
          adornment={<EmailIcon />}
        />
      </Grid>
    </Grid>
  );
}
