import React from "react";
import { Grid, Grow, Card, Button } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useSelector } from "react-redux";

import RemovalCard from "../Card";
import ModalViewRemoval from "../ViewModal";
import Nothing from "../Nothing";
import RemovalCardExtra from "../CardExtra";

export default function RemovalsPrevius() {
  const removals = useSelector((state) => state.removals);
  const [viewRemoval, setViewRemoval] = React.useState(false);
  const [removal, setRemoval] = React.useState({});

  const [data, setData] = React.useState({
    completes: [],
    incompletes: [],
    extras: [],
  });

  const getRemovalStatus = (removalTemp) => {
    let materials = 0;
    materials = removalTemp.materials?.reduce(
      (sum, current) => (sum += current.quantity),
      0
    );
    if (!removalTemp.datetimeLastModification) {
      return 0;
    } else if (!removalTemp.transporterID) {
      return 1;
    } else if (!removalTemp.payment) {
      return 2;
    } else if (!materials) {
      return 3;
    } else if (materials) {
      return 4;
    }
  };

  React.useEffect(() => {
    if (removals.previus) {
      let status = 0;
      let incompletes = [];
      let completes = [];
      let extras = [];

      removals.previus.forEach((thisRemoval) => {
        if (thisRemoval.author === "WEBAPP_EXTRA" && thisRemoval.status === "PENDING_TRANS") {
          extras.push(thisRemoval);
          return;
        }
        if (thisRemoval.status !== "AVAILABLE") {
          status = getRemovalStatus(thisRemoval);
          if (status === 4) {
            completes.push({
              ...thisRemoval,
              status,
            });
          } else {
            incompletes.push({
              ...thisRemoval,
              status,
            });
          }
        }
      });
      setData({
        incompletes,
        completes,
        extras,
      });
    }
  }, [removals.previus]);

  return (
    <Grid container>
      {viewRemoval && (
        <ModalViewRemoval removal={removal} setViewRemoval={setViewRemoval} />
      )}
      <Grid item xs={12}>
        <Content
          removals={data.completes}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          text="Retiros completos"
        />
      </Grid>
      <Grid item xs={12}>
        <Content
          removals={data.incompletes}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          text="Retiros aun en curso"
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Content
          removals={data.extras}
          setRemoval={setRemoval}
          setViewRemoval={setViewRemoval}
          text="Retiros extras"
        />
      </Grid> // eliminado hasta solucionar la forma de obtener los retiros extras del mes anterior*/} 
    </Grid>
  );
}

function Content({ removals, setRemoval, setViewRemoval, text }) {
  const [view, setView] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (removals) {
      setData(removals);
    }
  }, [removals]);
  return (
    <Card style={{ padding: 12, margin: 6 }}>
      <Grid container justify="space-between" direction="row">
        <Grid item>
          <Button
            onClick={() => setView(!view)}
            color="primary"
            startIcon={view ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {text} ({data.length})
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="text" onClick={() => setView(!view)}>
            {!view ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </Button>
        </Grid>
      </Grid>
      {view && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr />
          </Grid>
          {!data.length && <Nothing text={"No hay retiros para mostrar"} />}
          {view && text === "Retiros extras" ?
            data.map((removalData, i) => (
              <Grow key={i} in={true} timeout={500 + 500 * i}>
                <Grid item xs={12} sm={4} md={3} key={i}>
                  <RemovalCardExtra
                    removal={removalData}
                    key={i}
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                  />
                </Grid>
              </Grow>
            ))
            :
            data.map((removalData, i) => (
              <Grow key={i} in={true} timeout={500 + 500 * i}>
                <Grid item xs={12} sm={4} md={3} key={i}>
                  <RemovalCard
                    removal={removalData}
                    key={i}
                    setRemoval={setRemoval}
                    setViewRemoval={setViewRemoval}
                  />
                </Grid>
              </Grow>
            ))
          }
        </Grid>
      )}
    </Card>
  );
}
