export const residuesTypes = [
  { name: "METALS", title: "Otros Metales", active: true },
  { name: "TETRAPAK", title: "Tetra pak", active: true },
  { name: "ALUMINIUM", title: "Aluminio", active: true },
  { name: "ELECTRONICS", title: "Electrónicos", active: true },
  { name: "PLASTIC", title: "Plástico", active: false },
  { name: "FILM", title: "Film", active: false },
  { name: "PAPER", title: "Papel", active: true },
  { name: "PAPERBOARD", title: "Cartón", active: true },
  { name: "TEXTILS", title: "Textil", active: true },
  { name: "PELD", title: "PELD", active: true },
  { name: "PET", title: "PET", active: true },
  { name: "ORGANICS", title: "Organicos", active: true },
  { name: "OIL", title: "Aceite", active: true },
  { name: "GLASS", title: "Vidrio", active: true },
  { name: "CEL", title: "Celulosa", active: false },
  { name: "WOOD", title: "Madera", active: true },
  { name: "OTHERS", title: "Otros", active: true },
  { name: "PET1", title: "PET1", active: true },
  { name: "AL", title: "Aceite lubricante", active: true },
];

export const parseFlag = (flag) => {
  const residue = residuesTypes.find((residue) => residue.name === flag);
  return residue ? residue.title : `Material ${flag} invalido`;
};

export const valiActive = (flag) => {
  const residue = residuesTypes.find((residue) => residue.name === flag);
  if(residue){
    return residue.active;
  }else{
    return false;
  }
  
};

