import React, { useEffect, useState } from "react";
import { TextField, Grid, MenuItem, Typography, Chip, formatMs } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
const style = {
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  InputLabelProps: {
    shrink: true,
  },
};

export default function Vehicle({ formData, setFormData, removal,  disabled, setNextStepAllowed }) {
  const utils = useSelector((state) => state.utils);
  const [autocompleteVehicle, setAutocompleteVehicle] = useState({});

  
  const [vehiclesOptions, setVehiclesOptions] = useState([]);

  
  const [autocompleteValues, setAutocompleteValues] = useState({
    local: "",
    transporter: "",
    disposition: "",
  });

  useEffect(() => {
    if (formData.vehicleID) {
      setNextStepAllowed(true);
    }else{
      setNextStepAllowed(false);
    }
    
    if (formData.localID || formData.transporterID || formData.dispositionID) {
      setAutocompleteValues({
        ...autocompleteValues,
        local: utils.locals.find((local) => local._id === formData.localID),
        disposition: utils.dispositions.dispositions.find((disposition) => disposition._id === formData.dispositionID),
      });
    }

  }, [formData.localID, formData.transporterID, formData.dispositionID]);

  useEffect(() => {

    
  setVehiclesOptions([null,...removal.transporterID.vehiclesID]);
    
    if (utils.dataTransporter) {
      
      
        
      utils.dataTransporter.vehiclesID.map((vehicle) => {
          if (vehicle._id === formData.vehicleID) {
            setAutocompleteVehicle(vehicle);
          }
        })
      
    }
  }, [utils.dataTransporter])

  
  function ItemVehicles(params) {
    return (
      <TextField
        {...params}
        margin="dense"
        label="Placa patente"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  };

  const handleVehicle = (k) => {
    setAutocompleteVehicle(k);
    setFormData({ ...formData, vehicleID: k._id })
    setNextStepAllowed(true);
  };



  return (
    <Grid item xs={12}>
            
    <Grid item xs={12} sm={12}>
      <Typography variant="body2" align="center" color="textSecondary">Para ingresar este retiro, por favor selecciona el vehículo a utilizar.</Typography>
    </Grid>
    <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
      <Grid item xs={12} sm={6}>
      <Typography variant="body2" align="center" color="textSecondary">Vehículo: </Typography>
    </Grid>
    <Grid item xs={12} sm={6}>
    {vehiclesOptions &&
      <Autocomplete
        {...style}
        options={utils.dataTransporter.vehiclesID}
        key={vehiclesOptions}
        disableClearable
        getOptionLabel={(option) => option.licensePlate ? option.licensePlate : "Selecciona una patente"}
        getOptionSelected={(option, value) => option === value}
        value={autocompleteVehicle}
        disabled={disabled}
        onChange={(_, k) => handleVehicle(k)}
        renderInput={(params) => ItemVehicles(params)}
      />
    }
  </Grid>
    </Grid>
    

  </Grid>
);
}
