import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  ButtonGroup,
  useTheme,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import General from "./steps/General";
import Containers from "./steps/Containers";
import Materials from "./steps/Materials";
import Resume from "./steps/Resume";
import PriceAndTerms from "./steps/PriceAndTerms";
import PropTypes from "prop-types";
import { useAppContext } from "../../../../context/AppContext";
import materialsList from "../../../../resources/materials";
import { useDispatch } from "react-redux";
import removalsActions from "../../../../redux/actions/removals";

export default function Index({ setNewRemoval, localID }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { userType } = useAppContext();

  const dispatch = useDispatch();
  const materials = materialsList.materials;
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    datetimeRemoval: "0",
    window: "A",
    dateString: "",
    image: "",
    description: "",
    check: false,
    localID: "EMPTY",
    containers: {
      littleContainer: 0,
      middleContainer: 0,
      bigContainer: 0,
      giantContainer: 0,
    },
    materials: null,
  });

  // Hook de error
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, [1500]);
    }
  }, [error]);

  useEffect(() => {
    formData.localID = localID;
  }, [localID]);

  // Iniciar materiales
  useEffect(() => {
    let temp = {};
    Object.keys(materials).forEach((key) => {
      temp[key] = false;
    });
    setFormData({ ...formData, materials: temp });
  }, []);

  // Verificadores

  const verifGeneral = () => {
    if (userType === "CUSTOMER") {
      if (
        formData.description &&
        formData.image &&
        formData.localID !== "EMPTY"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (formData.description && formData.image) {
        return true;
      } else {
        return false;
      }
    }
  };

  const verifMaterials = () => {
    let value = false;
    Object.keys(materials).forEach((key) => {
      if (formData.materials[key]) {
        value = true;
      }
    });
    return value;
  };

  const verifContainers = () => {
    if (
      formData.containers.littleContainer +
      formData.containers.middleContainer +
      formData.containers.bigContainer +
      formData.containers.giantContainer
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Funcion de step

  const handleNext = () => {
    if (step === 1) {
      if (verifGeneral()) {
        setStep(2);
        return;
      } else {
        setError(true);
      }
    }
    if (step === 2) {
      if (verifMaterials()) {
        setStep(3);
        return;
      } else {
        setError(true);
      }
    }
    if (step === 3) {
      if (verifContainers()) {
        setStep(4);
        return;
      } else {
        setError(true);
      }
    }
    if (step === 4) {
      setStep(5);
    }
    if (step === 5 && formData.check) {
      dispatch(removalsActions.createRemovalExtra({ formData }));
      setNewRemoval(false);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={null}
      maxWidth="md"
      fullWidth
      scroll="body"
      fullScreen={isMobile}
    >
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" component="p">
              Solicitar retiro extra
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={() => setNewRemoval(false)}
              size="large"
            >
              <ClearOutlinedIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr></hr>
          </Grid>
        </Grid>
        {step === 1 && (
          <General
            formData={formData}
            setFormData={setFormData}
            error={error}
            setError={setError}
            userType={userType}
          />
        )}
        {step === 2 && (
          <Materials
            formData={formData}
            setFormData={setFormData}
            error={error}
          />
        )}
        {step === 3 && (
          <Containers
            formData={formData}
            setFormData={setFormData}
            error={error}
          />
        )}
        {step === 4 && <Resume formData={formData} setFormData={setFormData} />}
        {step === 5 && (
          <PriceAndTerms formData={formData} setFormData={setFormData} />
        )}
      </DialogContent>
      <DialogActions>
        <ButtonGroup size="medium" fullWidth>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<ArrowBackIosIcon />}
            onClick={() => setStep(step === 1 ? 1 : step - 1)}
          >
            Atras
          </Button>
          <Button
            variant={step === 5 && !formData.check ? "outlined" : "contained"}
            color="primary"
            fullWidth
            style={
              step === 5 && !formData.check
                ? {}
                : {
                  background:
                    "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
                  color: "#fff",
                }
            }
            onClick={() => handleNext()}
            endIcon={
              step !== 5 ? <ArrowForwardIosIcon /> : <CheckCircleOutlineIcon />
            }
          >
            {step !== 5 ? "Siguiente" : "Solicitar retiro"}
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}

Index.propTypes = {
  setNewRemoval: PropTypes.func.isRequired,
};
