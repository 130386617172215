import React from "react";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Menu as MenuIcon } from "@material-ui/icons";
import ImageIcon from "@material-ui/icons/Image";
import logo from "../../resources/images/logo.png";
import ProfileCard from "./ProfileCard";

import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";

import {
  Grid,
  SwipeableDrawer,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  IconButton,
  LinearProgress,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import SideNavigation from "./SideNavigation";
import { useAppContext } from "../../context/AppContext";
import { Redirect } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  userMenu: {
    paddingLeft: "30%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20%",
    },
  },
  drawer: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function MenuAppBar() {
  const { loged, logout, loading, setThemeColor, themeColor } = useAppContext();

  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSideNav, setOpenSideNav] = React.useState(false);
  const [redirect, setRedirect] = React.useState(<div />);
  const open = Boolean(anchorEl);

  const viewWallpaper = () => {
    setRedirect(<Redirect to="/wallpapers" />);
    setTimeout(() => {
      setRedirect(<div />);
    }, 1000);
  };

  const toggleSideNav = (open) => {
    setOpenSideNav(open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {redirect}
      <AppBar
        color="secondary"
        // style={{
        //   background:
        //     "linear-gradient(74deg, rgba(48,63,159,1) 21%, rgba(69,202,252,0.9) 100%)",
        // }}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            {isMobile && loged && (
              <Grid item>
                <IconButton onClick={() => toggleSideNav(true)}>
                  <MenuIcon style={{ color: "white" }} />
                </IconButton>
                <SwipeableDrawer
                  classes={{
                    paper: classes.drawer,
                  }}
                  open={openSideNav}
                  onOpen={() => toggleSideNav(true)}
                  onClose={() => toggleSideNav(false)}
                >
                  <SideNavigation
                    mobile={isMobile}
                    closeSideNav={() => toggleSideNav(false)}
                  />
                </SwipeableDrawer>
              </Grid>
            )}

            {!isMobile && (
              <Grid item>
                <img
                  src={logo}
                  alt="Acción Circular"
                  style={{ height: 50, marginLeft: isMobile ? 5 : 20 }}
                />
              </Grid>
            )}
            {loged && (
              <Grid item>
                <IconButton
                  aria-label="ayuda"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() =>
                    setThemeColor(themeColor === "light" ? "dark" : "light")
                  }
                >
                  {themeColor === "light" ? (
                    <Brightness4Icon />
                  ) : (
                    <Brightness7Icon />
                  )}
                </IconButton>
                <IconButton
                  aria-label="wallpapers"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => viewWallpaper()}
                >
                  <ImageIcon />
                </IconButton>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem disableGutters disableRipple disableTouchRipple>
                    <ProfileCard logout={logout} />
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
        </Toolbar>
        {loading && (
          <LinearProgress
            color="secondary"
            style={{ height: 5, marginTop: -5 }}
          />
        )}
      </AppBar>
    </div>
  );
}
