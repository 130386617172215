import React from "react";
import { parseFlag } from "../../../../resources/residuesTypes";
import { Typography } from "@material-ui/core";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
} from "recharts";

const colorsDataPieMaterials = [
  "#45cafc",
  "#D5DBDB",
  "#FFBB28",
  "#0088FE",
  "#85929E",
  "#D7BDE2",
  "#00C49F",
  "#A04000",
  "#E5E8E8",
  "",
];

export default function Chart({ materials }) {
  const [dataPieChartMaterials, setDataPieChartMaterials] = React.useState([]);

  React.useEffect(() => {
    if (materials) {
      const total = materials.reduce(
        (ac, material) => ac + material.quantity * 1,
        0
      );
      setDataPieChartMaterials(
        materials.map((material) => {
          return {
            name: parseFlag(material.material),
            value: Math.round((material.quantity * 100) / total),
          };
        })
      );
    }
  }, [materials]);

  return (
    <div>
      <Typography variant="h6">
        Relación porcentual por tipo de material
      </Typography>
      <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
        <PieChart width={400}>
          <Pie
            data={dataPieChartMaterials}
            innerRadius={50}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
          >
            {dataPieChartMaterials.map((_entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  colorsDataPieMaterials[index % colorsDataPieMaterials.length]
                }
              />
            ))}
          </Pie>
          <Legend verticalAlign="bottom" />
          <Tooltip formatter={(value) => value + " %"} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
