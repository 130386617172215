import React from "react";
import { Grid, Card, CardContent, Grow, Typography } from "@material-ui/core";
import CardLocal from "./CardLocal";
import StoreIcon from "@material-ui/icons/Store";
import Header from "../../Navigation/Header";
import {
  LocalsContextProvider,
  useLocalsContext,
} from "../../../context/LocalsContext";

function Index() {
  const { locals } = useLocalsContext();

  return (
    <Card>
      <CardContent>
        <Header
          title={
            <Typography component="h2" variant="h4">
              <StoreIcon style={{ fontSize: 34, marginBottom: 6 }} /> Tus
              Sucursales
            </Typography>
          }
        />
        <hr />
        <Grid container spacing={2}>
          {locals.map((local, i) => (
            <Grow key={i} in={true} timeout={500 + 500 * i}>
              <Grid item sm={3} xs={12} key={i}>
                <CardLocal local={local} />
              </Grid>
            </Grow>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default function Locals() {
  return (
    <LocalsContextProvider>
      <Index />
    </LocalsContextProvider>
  );
}
